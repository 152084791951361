import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import styles from './NewFundraiser.module.css';
import { createFundraiser } from '../api/platform/integrations/salesforce/fundraiser/createFundraiser';
import { FundraiserCategory, Address, USState, Country, CreateFundraiserResult } from '../api/platform/integrations/salesforce/fundraiser/types';
import CharacterLimitedTextField from '../components/CharacterLimitedTextField';
import AddressForm from '../components/AddressForm';
import { getEarliestStartDate, getLatestStartDate, getLatestEndDate, formatDateForInput } from '../utils/dateValidation';

interface FormErrors {
  error?: string;
  startDate?: string;
  endDate?: string;
  description?: string;
  recipientEmail?: string;
  zipCode?: string;
  additionalRetailers?: string;
  checkMemo?: string;
}

// Update the form state type to allow empty string for category
type FormCategory = FundraiserCategory | '';

const FUNDRAISER_CATEGORIES: FundraiserCategory[] = [
  'Educational',
  'Extra-Curricular',
  'Natural Disaster',
  'Medical',
  'Miscellaneous',
  'Community',
  'Animal Shelter/Rescue'
];

const emptyAddress: Address = {
  street: '',
  address2: '',
  city: '',
  state: '' as USState,
  zipCode: '',
  country: 'United States' as Country,
  isAPO: false
};

const NewFundraiser: React.FC = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});
  
  const [formData, setFormData] = useState({
    // Section 1
    Fundraiser_Start_Date__c: formatDateForInput(new Date(getEarliestStartDate().getTime() + 24 * 60 * 60 * 1000)),
    Fundraiser_End_Date__c: '',
    Brief_Description__c: '',
    Additional_Retailers__c: [] as string[],

    // Section 2
    Group_Name__c: '',
    Group_Category__c: '' as FormCategory,
    Group_Attention_or_C_O__c: '',
    Group_Email_Address__c: '',
    Group_Street__c: '',
    Group_Address_2__c: '',
    Group_City__c: '',
    Group_Zip_Postal_Code__c: '',
    Group_State_Province__c: '' as USState,
    Group_Country__c: 'United States' as Country,
    Group_Is_APO__c: false,

    // Section 3
    Make_Check_Payable_To__c: '',
    Group_Tax_Exemption_Number__c: '',
    Check_Memo_if_Applicable__c: '',
    Use_Same_Address__c: true,
    Check_Mailing_Address_1__c: '',
    Check_Mailing_Address_2__c: '',
    Check_Mailing_City__c: '',
    Check_Mailing_State__c: '' as USState,
    Check_Mailing_Zip_Code__c: '',
    Check_Mailing_Country__c: 'United States' as Country,
    Check_Is_APO__c: false,
  });

  useEffect(() => {
    if (formData.Fundraiser_Start_Date__c) {
      const startDate = new Date(formData.Fundraiser_Start_Date__c);
      if (!isNaN(startDate.getTime())) {
        const maxEndDate = getLatestEndDate(startDate);
        if (!formData.Fundraiser_End_Date__c || new Date(formData.Fundraiser_End_Date__c) > maxEndDate) {
          setFormData(prev => ({
            ...prev,
            Fundraiser_End_Date__c: formatDateForInput(maxEndDate)
          }));
        }
      }
    }
  }, [formData.Fundraiser_Start_Date__c]);

  // Validation functions
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateZipCode = (zip: string): boolean => {
    return /^\d{5}$/.test(zip);
  };

  const validateDescription = (desc: string): boolean => {
    return desc.length >= 20 && desc.length <= 250;
  };

  const validateCheckMemo = (memo: string): boolean => {
    return memo.length <= 40;
  };

  const validateForm = (): FormErrors => {
    const newErrors: FormErrors = {};

    // Validate dates
    const startDate = new Date(formData.Fundraiser_Start_Date__c);
    const earliestStart = getEarliestStartDate();
    const latestStart = getLatestStartDate();
    
    if (startDate < earliestStart) {
      newErrors.startDate = 'Start date must be at least 3 business days from today';
    }
    if (startDate > latestStart) {
      newErrors.startDate = 'Start date cannot be more than 60 days in the future';
    }

    // Validate description
    if (!validateDescription(formData.Brief_Description__c)) {
      newErrors.description = 'Description must be between 20 and 250 characters';
    }

    // Validate email
    if (!validateEmail(formData.Group_Email_Address__c)) {
      newErrors.recipientEmail = 'Please enter a valid email address';
    }

    // Validate zip codes
    if (!validateZipCode(formData.Group_Zip_Postal_Code__c)) {
      newErrors.zipCode = 'ZIP code must be 5 digits';
    }

    // Validate check memo if provided
    if (formData.Check_Memo_if_Applicable__c && !validateCheckMemo(formData.Check_Memo_if_Applicable__c)) {
      newErrors.checkMemo = 'Check memo cannot exceed 40 characters';
    }

    return newErrors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      setIsSubmitting(false);
      return;
    }

    // Check if category is empty
    if (!formData.Group_Category__c) {
      setErrors(prev => ({
        ...prev,
        error: 'Please select a fundraiser category'
      }));
      setIsSubmitting(false);
      return;
    }

    try {
      // Create a copy of the form data
      const submissionData = { ...formData };

      // If using same address, copy the group address fields to check mailing fields
      if (formData.Use_Same_Address__c) {
        submissionData.Check_Mailing_Address_1__c = formData.Group_Street__c;
        submissionData.Check_Mailing_Address_2__c = formData.Group_Address_2__c;
        submissionData.Check_Mailing_City__c = formData.Group_City__c;
        submissionData.Check_Mailing_State__c = formData.Group_State_Province__c;
        submissionData.Check_Mailing_Zip_Code__c = formData.Group_Zip_Postal_Code__c;
        submissionData.Check_Mailing_Country__c = formData.Group_Country__c;
        submissionData.Check_Is_APO__c = formData.Group_Is_APO__c;
      }

      const response = await createFundraiser({
        ...submissionData,
        Group_Category__c: formData.Group_Category__c as FundraiserCategory,
      });

      if (!response.success) {
        throw new Error(response.error || 'Failed to create fundraiser');
      }

      if (!response.data.fundraiserNumber) {
        throw new Error('No fundraiser number received from server');
      }

      // Navigate to the new fundraiser's detail page
      navigate(`/fundraisers/${response.data.fundraiserNumber}`);
    } catch (err) {
      setErrors(prev => ({
        ...prev,
        error: err instanceof Error ? err.message : 'Failed to create fundraiser'
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (field: keyof typeof formData, value: any) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.content}>
          {errors.error && (
            <div className="bg-red-50 text-red-700 p-4 rounded-md mb-4">
              {errors.error}
            </div>
          )}

          <div className={styles.textContainer}>
            <h1 className={styles.header}>Create New Fundraiser</h1>
            <p className={styles.helpText}>
              Fill out the form below to submit a new fundraiser request.
            </p>
          </div>

          <form onSubmit={handleSubmit} className={styles.form}>
            {/* Fundraiser Dates */}
            <div className={styles.section}>
              <h2 className={styles.sectionTitle}>Fundraiser Dates</h2>
              <div className={styles.sectionContent}>
                <div className={styles.dateGroup}>
                  <div className={styles.formGroup}>
                    <label htmlFor="startDate" className={styles.formLabel}>Start Date</label>
                    <input
                      type="date"
                      id="startDate"
                      value={formData.Fundraiser_Start_Date__c}
                      onChange={(e) => {
                        const newStartDate = e.target.value;
                        handleChange('Fundraiser_Start_Date__c', newStartDate);
                        
                        // Clear end date if it's beyond max allowed
                        const maxEndDate = getLatestEndDate(new Date(newStartDate));
                        const currentEndDate = new Date(formData.Fundraiser_End_Date__c);
                        if (currentEndDate > maxEndDate) {
                          handleChange('Fundraiser_End_Date__c', '');
                        }
                      }}
                      min={formatDateForInput(new Date(getEarliestStartDate().getTime() + 24 * 60 * 60 * 1000))}
                      max={formatDateForInput(getLatestStartDate())}
                      required
                      className={styles.input}
                    />
                    <span className={styles.helpText}>
                      Must be between 3 and 60 days from today
                    </span>
                  </div>

                  <div className={styles.formGroup}>
                    <label htmlFor="endDate" className={styles.formLabel}>End Date</label>
                    <input
                      type="date"
                      id="endDate"
                      value={formData.Fundraiser_End_Date__c}
                      onChange={(e) => handleChange('Fundraiser_End_Date__c', e.target.value)}
                      min={formData.Fundraiser_Start_Date__c || formatDateForInput(getEarliestStartDate())}
                      max={formData.Fundraiser_Start_Date__c ? formatDateForInput(getLatestEndDate(new Date(formData.Fundraiser_Start_Date__c))) : undefined}
                      required
                      disabled={!formData.Fundraiser_Start_Date__c}
                      className={styles.input}
                    />
                    <span className={styles.helpText}>
                      Maximum 2 weeks from start date
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Fundraiser Info */}
            <div className={styles.section}>
              <h2 className={styles.sectionTitle}>Fundraiser Information</h2>
              <div className={styles.sectionContent}>
                <div className={styles.formGroup}>
                  <label htmlFor="category" className={styles.formLabel}>Fundraiser Category</label>
                  <select
                    id="category"
                    value={formData.Group_Category__c}
                    onChange={(e) => handleChange('Group_Category__c', e.target.value as FundraiserCategory)}
                    required
                    className={styles.select}
                  >
                    <option value="">Select category</option>
                    {FUNDRAISER_CATEGORIES.map(category => (
                      <option key={category} value={category}>{category}</option>
                    ))}
                  </select>
                </div>

                <div className={styles.formGroup}>
                  <CharacterLimitedTextField
                    id="description"
                    value={formData.Brief_Description__c}
                    onChange={(value) => handleChange('Brief_Description__c', value)}
                    maxLength={250}
                    required
                    label="Fundraiser Description"
                    className={styles.textarea}
                    labelClassName={styles.formLabel}
                    minHeight="150px"
                    resizable={true}
                    placeholder="Describe your fundraiser and its purpose (minimum 20 characters, maximum 250)"
                  />
                  {formData.Brief_Description__c.length > 0 && formData.Brief_Description__c.length < 20 && (
                    <span className={styles.helpText} style={{ color: 'red' }}>
                      Description must be at least 20 characters long (currently {formData.Brief_Description__c.length})
                    </span>
                  )}
                </div>
              </div>
            </div>

            {/* Recipient Info */}
            <div className={styles.section}>
              <h2 className={styles.sectionTitle}>Recipient Information</h2>
              <div className={styles.sectionContent}>
                <div className={styles.formGroup}>
                  <label htmlFor="recipientName" className={styles.formLabel}>Recipient or Organization Name</label>
                  <input
                    type="text"
                    id="recipientName"
                    value={formData.Group_Name__c}
                    onChange={(e) => handleChange('Group_Name__c', e.target.value)}
                    required
                    className={styles.input}
                    placeholder="Enter recipient or organization name"
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="recipientAttn" className={styles.formLabel}>Recipient Attn (Optional)</label>
                  <input
                    type="text"
                    id="recipientAttn"
                    value={formData.Group_Attention_or_C_O__c || ''}
                    onChange={(e) => handleChange('Group_Attention_or_C_O__c', e.target.value)}
                    className={styles.input}
                    placeholder="Attention to"
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="recipientEmail" className={styles.formLabel}>Donation Recipient's Email</label>
                  <input
                    type="email"
                    id="recipientEmail"
                    value={formData.Group_Email_Address__c}
                    onChange={(e) => handleChange('Group_Email_Address__c', e.target.value)}
                    required
                    className={styles.input}
                    placeholder="Enter recipient's email"
                  />
                </div>

                {/* Recipient Address */}
                <AddressForm
                  address={{
                    street: formData.Group_Street__c,
                    address2: formData.Group_Address_2__c,
                    city: formData.Group_City__c,
                    state: formData.Group_State_Province__c,
                    zipCode: formData.Group_Zip_Postal_Code__c,
                    country: formData.Group_Country__c,
                    isAPO: formData.Group_Is_APO__c
                  }}
                  onChange={(newAddress: Address) => {
                    handleChange('Group_Street__c', newAddress.street);
                    handleChange('Group_Address_2__c', newAddress.address2);
                    handleChange('Group_City__c', newAddress.city);
                    handleChange('Group_State_Province__c', newAddress.state);
                    handleChange('Group_Zip_Postal_Code__c', newAddress.zipCode);
                    handleChange('Group_Country__c', newAddress.country);
                    handleChange('Group_Is_APO__c', newAddress.isAPO);
                  }}
                  isAPO={formData.Group_Is_APO__c}
                  onAPOChange={(isAPO) => handleChange('Group_Is_APO__c', isAPO)}
                  className={styles.addressForm}
                />
              </div>
            </div>

            {/* Donation Info */}
            <div className={styles.section}>
              <h2 className={styles.sectionTitle}>Donation Information</h2>
              <div className={styles.sectionContent}>
                <div className={styles.formGroup}>
                  <label htmlFor="payableTo" className={styles.formLabel}>Make Check Payable To</label>
                  <input
                    type="text"
                    id="payableTo"
                    value={formData.Make_Check_Payable_To__c}
                    onChange={(e) => handleChange('Make_Check_Payable_To__c', e.target.value)}
                    required
                    className={styles.input}
                    placeholder="Enter payee name"
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="taxExemptNumber" className={styles.formLabel}>501c3/TAX EXEMPT# (Optional)</label>
                  <input
                    type="text"
                    id="taxExemptNumber"
                    value={formData.Group_Tax_Exemption_Number__c || ''}
                    onChange={(e) => handleChange('Group_Tax_Exemption_Number__c', e.target.value)}
                    className={styles.input}
                    placeholder="Enter tax exempt number"
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="checkMemo" className={styles.formLabel}>Check Memo (Optional)</label>
                  <input
                    type="text"
                    id="checkMemo"
                    value={formData.Check_Memo_if_Applicable__c || ''}
                    onChange={(e) => handleChange('Check_Memo_if_Applicable__c', e.target.value)}
                    className={styles.input}
                    maxLength={4000}
                    placeholder="Enter check memo"
                  />
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.checkboxLabel}>
                    <input
                      type="checkbox"
                      checked={formData.Use_Same_Address__c}
                      onChange={(e) => handleChange('Use_Same_Address__c', e.target.checked)}
                      className={styles.checkbox}
                    />
                    <span className={styles.checkboxText}>Same as fundraiser address</span>
                  </label>
                </div>

                {!formData.Use_Same_Address__c && (
                  <AddressForm
                    address={{
                      street: formData.Check_Mailing_Address_1__c || '',
                      address2: formData.Check_Mailing_Address_2__c || '',
                      city: formData.Check_Mailing_City__c || '',
                      state: formData.Check_Mailing_State__c || '',
                      zipCode: formData.Check_Mailing_Zip_Code__c || '',
                      country: formData.Check_Mailing_Country__c || 'United States',
                      isAPO: formData.Check_Is_APO__c || false
                    }}
                    onChange={(newAddress: Address) => {
                      handleChange('Check_Mailing_Address_1__c', newAddress.street);
                      handleChange('Check_Mailing_Address_2__c', newAddress.address2);
                      handleChange('Check_Mailing_City__c', newAddress.city);
                      handleChange('Check_Mailing_State__c', newAddress.state);
                      handleChange('Check_Mailing_Zip_Code__c', newAddress.zipCode);
                      handleChange('Check_Mailing_Country__c', newAddress.country);
                      handleChange('Check_Is_APO__c', newAddress.isAPO);
                    }}
                    isAPO={formData.Check_Is_APO__c}
                    onAPOChange={(isAPO) => handleChange('Check_Is_APO__c', isAPO)}
                    className={styles.addressForm}
                  />
                )}
              </div>
            </div>

            <div className={styles.buttonGroup}>
              <button
                type="button"
                onClick={() => navigate('/fundraisers')}
                className={styles.cancelButton}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={styles.submitButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Creating...' : 'Create Fundraiser'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default NewFundraiser; 