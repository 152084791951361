import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import { config } from '@/config';
import { isLocalDev } from '@/utils/environment';
import { logger } from '@/utils/logger';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return null; // Or a loading spinner
  }

  if (!isAuthenticated) {
    logger.debug('Not authenticated, redirecting', {
      metadata: {
        redirectUrl: config.signOutRedirectUrl
      }
    });

    // In development, navigate to local signin
    if (isLocalDev()) {
      return <Navigate to="/signin" />;
    }

    // In other environments, redirect to SSO
    window.location.href = config.signOutRedirectUrl;
    return null;
  }

  return <>{children}</>;
};

export default PrivateRoute; 