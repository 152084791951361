import axiosInstance from '@/api/axiosInstance';
import { logger } from '@/utils/logger';

interface UpdateCaseRequest {
  Status?: string;
  Comments?: string;
}

export const updateCase = async (caseId: string, data: UpdateCaseRequest): Promise<void> => {
  try {
    logger.info('Updating case:', { 
      metadata: { 
        caseId, 
        data 
      } 
    });
    await axiosInstance.patch(`/i/s/c/${caseId}`, data);
  } catch (error) {
    logger.error('Error updating case:', { metadata: error });
    throw new Error(
      error instanceof Error 
        ? error.message 
        : 'Failed to update case'
    );
  }
};

export const closeCase = async (caseId: string): Promise<void> => {
  try {
    logger.info('Closing case:', { metadata: { caseId } });
    await updateCase(caseId, { Status: 'Resolved' });
  } catch (error) {
    logger.error('Error closing case:', { metadata: error });
    throw new Error(
      error instanceof Error 
        ? error.message 
        : 'Failed to close case'
    );
  }
};

export const addComment = async (caseId: string, comment: string): Promise<void> => {
  try {
    logger.info('Adding comment to case:', { 
      metadata: { 
        caseId,
        commentLength: comment.length 
      } 
    });
    await updateCase(caseId, { Comments: comment });
  } catch (error) {
    logger.error('Error adding comment:', { metadata: error });
    throw new Error(
      error instanceof Error 
        ? error.message 
        : 'Failed to add comment'
    );
  }
};

export const reopenCase = async (caseId: string): Promise<void> => {
  try {
    logger.info('Reopening case:', { metadata: { caseId } });
    await updateCase(caseId, { Status: 'Open' });
  } catch (error) {
    logger.error('Error reopening case:', { metadata: error });
    throw new Error(
      error instanceof Error 
        ? error.message 
        : 'Failed to reopen case'
    );
  }
};
