import axiosInstance from '@/api/axiosInstance';
import { logger } from '@/utils/logger';
import { BaseFundraiserFields, FundraiserDetail, CreateFundraiserResult } from './types';

export const createFundraiser = async (request: BaseFundraiserFields): Promise<CreateFundraiserResult> => {
  try {
    // Map the address fields to match the API's expected format
    const mappedRequest = {
      ...request,
      // Group address
      Group_Street__c: request.Group_Street__c || '',
      Group_Address_2__c: request.Group_Address_2__c || '',
      Group_City__c: request.Group_City__c || '',
      Group_State_Province__c: request.Group_State_Province__c || '',
      Group_Zip_Postal_Code__c: request.Group_Zip_Postal_Code__c || '',
      Group_Country__c: request.Group_Country__c || 'United States',
      
      // Check mailing address (only if not using same address)
      Check_Mailing_Address_1__c: request.Use_Same_Address__c 
        ? request.Group_Street__c
        : (request.Check_Mailing_Address_1__c || ''),
      Check_Mailing_Address_2__c: request.Use_Same_Address__c
        ? request.Group_Address_2__c
        : (request.Check_Mailing_Address_2__c || ''),
      Check_Mailing_City__c: request.Use_Same_Address__c
        ? request.Group_City__c
        : (request.Check_Mailing_City__c || ''),
      Check_Mailing_State__c: request.Use_Same_Address__c
        ? request.Group_State_Province__c
        : (request.Check_Mailing_State__c || ''),
      Check_Mailing_Zip_Code__c: request.Use_Same_Address__c
        ? request.Group_Zip_Postal_Code__c
        : (request.Check_Mailing_Zip_Code__c || ''),
      Check_Mailing_Country__c: request.Use_Same_Address__c
        ? request.Group_Country__c
        : (request.Check_Mailing_Country__c || 'United States'),
    };

    const response = await axiosInstance.post<{success: boolean; data: FundraiserDetail}>('/i/s/f', mappedRequest);
    
    logger.debug('Create fundraiser response:', { metadata: response.data });

    if (!response.data?.success || !response.data?.data?.fundraiserNumber) {
      throw new Error('Invalid response format from server');
    }

    return {
      success: true,
      data: response.data.data
    };
  } catch (error) {
    logger.error('Error creating fundraiser:', { metadata: error });
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to create fundraiser'
    };
  }
}; 