import axiosInstance from '@/api/axiosInstance';
import { logger } from '@/utils/logger';

export interface Product {
  id: string;
  name: string;
  productCode: string;
  description: string | null;
  sku: string;
  family: string;
  size: string;
  itemName: string;
  retailPrice: number;
  wholesalePrice: number;
}

export interface ProductsResponse {
  success: boolean;
  products: Product[];
  error?: string;
  details?: string;
}

// Size order mapping for consistent sorting
const SIZE_ORDER: { [key: string]: number } = {
  // Standard sizes
  'XXS': 1, 'XS': 2, 'S': 3, 'M': 4, 'L': 5, 'XL': 6, '2XL': 7, '3XL': 8, '4XL': 9,
  // Plus sizes (after XL sizes)
  '1X': 20, '2X': 21, '3X': 22,
  // C sizes
  '1C': 30, '2C': 31, '3C': 32,
  // Special sizes
  'S/M': 40, 'L/XL': 41, 'TWEEN': 42, 'O/S': 43, 'T/C': 44, 'T/C2': 45,
  // Numeric sizes (children's)
  '2': 50, '4': 51, '6': 52, '8': 53, '10': 54, '12': 55, '14': 56,
  // Waist sizes
  '24': 60, '26': 61, '28': 62, '30': 63, '32': 64, '34': 65, '36': 66, 
  '38': 67, '40': 68, '42': 69, '44': 70, '46': 71
};

// Helper function to sort sizes
export const sortSizes = (sizes: string[]): string[] => {
  return sizes.sort((a, b) => {
    const aOrder = SIZE_ORDER[a.toUpperCase()] || 999;
    const bOrder = SIZE_ORDER[b.toUpperCase()] || 999;
    return aOrder - bOrder;
  });
};

export const getActiveProducts = async (): Promise<Product[]> => {
  try {
    logger.info('Fetching active products...');
    
    const response = await axiosInstance.get<ProductsResponse>('/i/s/p/active');
    
    logger.debug('Raw response:', { 
      metadata: response.data 
    });

    if (!response.data.success) {
      logger.error('API returned success: false', {
        metadata: {
          error: response.data.error,
          details: response.data.details
        }
      });
      throw new Error(response.data.details || response.data.error || 'Failed to fetch products');
    }

    logger.info('Successfully fetched products:', {
      metadata: {
        totalCount: response.data.products.length,
        uniqueFamilies: Array.from(new Set(response.data.products.map(p => p.family))),
        sampleProduct: response.data.products[0]
      }
    });

    return response.data.products;
  } catch (error) {
    logger.error('Error fetching active products:', {
      metadata: error
    });
    throw error;
  }
};

// Update the existing getProductSizes function
export const getProductSizes = (products: Product[], family: string): string[] => {
  const sizes = Array.from(
    new Set(
      products
        .filter(p => p.family === family)
        .map(p => p.size)
    )
  ).filter(Boolean);

  const sortedSizes = sortSizes(sizes);
  logger.info(`Available sizes for ${family}:`, {
    metadata: sortedSizes
  });
  return sortedSizes;
};

// Helper function to get unique product families
export const getProductFamilies = (products: Product[]): string[] => {
  const families = Array.from(
    new Set(products.map(p => p.family))
  ).filter(Boolean);

  logger.info('Available product families:', {
    metadata: families
  });
  return families;
};

// Helper to get products by family
export const getProductsByFamily = (products: Product[], family: string): Product[] => {
  return products.filter(p => p.family === family);
};
