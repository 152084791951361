import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Layout from '../components/Layout';
import styles from './FundraiserDetail.module.css';
import { getFundraiser } from '../api/platform/integrations/salesforce/fundraiser/getFundraiser';
import { FundraiserDetail as IFundraiserDetail } from '../api/platform/integrations/salesforce/fundraiser/types';
import SectionHeader from '../components/SectionHeader';
import { Helmet } from 'react-helmet-async';
import { APP_TITLE_SUFFIX } from '../constants';

interface MailingAddress {
  lineOne: string;
  lineTwo?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}

interface DetailFundraiser {
  id: string;
  description: string;
  fundraiserNumber: string;
  startDate: string;
  endDate: string;
  category: string;
  fundraisingFor: string;
  attentionCO?: string;
  orgEmail: string;
  mailingAddress: MailingAddress;
  taxExemptionNumber?: string;
  makeCheckPayableTo: string;
  checkMemo?: string;
  checkNumber?: string | null;
  status: string;
  piecesSold?: number | null;
  leadRetailer?: string;
}

const mapAddressToDisplayFormat = (address: any): MailingAddress => {
  return {
    lineOne: address.street || address.lineOne,
    lineTwo: address.address2 || address.lineTwo,
    city: address.city,
    state: address.state,
    postalCode: address.zipCode || address.postalCode,
    country: address.country
  };
};

const mapApiDataToDetailFundraiser = (apiData: IFundraiserDetail): DetailFundraiser => {
  return {
    id: apiData.id,
    description: apiData.description,
    fundraiserNumber: apiData.fundraiserNumber,
    startDate: apiData.startDate,
    endDate: apiData.endDate,
    category: apiData.category,
    fundraisingFor: apiData.fundraisingFor,
    attentionCO: apiData.attentionCO,
    orgEmail: apiData.orgEmail,
    mailingAddress: mapAddressToDisplayFormat(apiData.mailingAddress),
    taxExemptionNumber: apiData.taxExemptionNumber,
    makeCheckPayableTo: apiData.makeCheckPayableTo,
    checkMemo: apiData.checkMemo,
    checkNumber: apiData.checkNumber,
    status: apiData.status,
    piecesSold: apiData.piecesSold,
    leadRetailer: apiData.leadRetailer
  };
};

const FundraiserDetail: React.FC = () => {
  const { fundraiserNumber } = useParams<{ fundraiserNumber: string }>();
  const location = useLocation();
  const [fundraiser, setFundraiser] = useState<DetailFundraiser | null>(
    location.state?.fundraiser ? mapApiDataToDetailFundraiser(location.state.fundraiser) : null
  );
  const [isLoading, setIsLoading] = useState(!location.state?.fundraiser);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchFundraiser = async () => {
      if (!fundraiserNumber) return;
      
      try {
        setError(null);
        const apiData = await getFundraiser(fundraiserNumber);
        const mappedData = mapApiDataToDetailFundraiser(apiData);
        setFundraiser(mappedData);
      } catch (err) {
        console.error('Error fetching fundraiser:', err);
        setError(err instanceof Error ? err.message : 'Failed to load fundraiser details');
      } finally {
        setIsLoading(false);
      }
    };

    if (!fundraiser) {
      fetchFundraiser();
    }
  }, [fundraiserNumber, fundraiser]);

  if (isLoading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-site-color"></div>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="bg-red-50 text-red-700 p-4 rounded-md m-4">
          {error}
        </div>
      </Layout>
    );
  }

  if (!fundraiser) {
    return (
      <Layout>
        <div className="text-center p-4">
          Fundraiser not found
        </div>
      </Layout>
    );
  }

  const formatStatus = (status: string) => {
    return status.replace(/_/g, ' ');
  };

  return (
    <Layout>
      <Helmet>
        <title>
          {fundraiser 
            ? `Fundraiser ${fundraiser.fundraiserNumber} | ${APP_TITLE_SUFFIX}`
            : `Fundraiser Detail | ${APP_TITLE_SUFFIX}`
          }
        </title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <div className={styles.headerSection}>
            <h1 className={styles.title}>{fundraiser.fundraisingFor}</h1>
          </div>

          <div className={styles.subContainer}>
            <div className={styles.leftContainer}>
              <div className={`${styles.section} ${styles.descriptionSection}`}>
                <SectionHeader title="Description" />
                <p className={styles.detailsContent}>{fundraiser.description}</p>
              </div>
            </div>

            <div className={styles.rightContainer}>
              <div className={`${styles.section} ${styles.detailsSection}`}>
                <SectionHeader title="Details" />
                <div className={styles.detailsGrid}>
                  <div className={styles.detailItem}>
                    <p><strong>Fundraiser Number:</strong></p>
                    <p className={styles.detailsContent}>{fundraiser.fundraiserNumber}</p>
                  </div>

                  <div className={styles.detailItem}>
                    <p><strong>Category:</strong></p>
                    <p className={styles.detailsContent}>{fundraiser.category}</p>
                  </div>

                  <div className={styles.detailItem}>
                    <p><strong>Status:</strong></p>
                    <p className={styles.detailsContent}>{formatStatus(fundraiser.status)}</p>
                  </div>

                  <div className={styles.detailItem}>
                    <p><strong>Dates:</strong></p>
                    <p className={styles.detailsContent}>
                      {new Date(fundraiser.startDate).toLocaleDateString()} - 
                      {new Date(fundraiser.endDate).toLocaleDateString()}
                    </p>
                  </div>

                  <div className={styles.detailItem}>
                    <p><strong>Contact Information:</strong></p>
                    <div className={styles.detailsContent}>
                      {fundraiser.attentionCO && <p>Attn: {fundraiser.attentionCO}</p>}
                      <p>{fundraiser.orgEmail}</p>
                    </div>
                  </div>

                  <div className={styles.detailItem}>
                    <p><strong>Mailing Address:</strong></p>
                    <div className={styles.addressBlock}>
                      <p>{fundraiser.mailingAddress.lineOne}</p>
                      {fundraiser.mailingAddress.lineTwo && (
                        <p>{fundraiser.mailingAddress.lineTwo}</p>
                      )}
                      <p>
                        {fundraiser.mailingAddress.city}, {fundraiser.mailingAddress.state} {fundraiser.mailingAddress.postalCode}
                      </p>
                      <p>{fundraiser.mailingAddress.country}</p>
                    </div>
                  </div>

                  <div className={styles.detailItem}>
                    <p><strong>Payment Information:</strong></p>
                    <div className={styles.detailsContent}>
                      <p>Payable to: {fundraiser.makeCheckPayableTo}</p>
                      {fundraiser.checkMemo && <p>Memo: {fundraiser.checkMemo}</p>}
                      {fundraiser.taxExemptionNumber && (
                        <p>Tax Exempt #: {fundraiser.taxExemptionNumber}</p>
                      )}
                    </div>
                  </div>

                  {fundraiser.piecesSold !== null && (
                    <div className={styles.detailItem}>
                      <p><strong>Pieces Sold:</strong></p>
                      <p className={styles.detailsContent}>{fundraiser.piecesSold}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FundraiserDetail; 