import axiosInstance from '@/api/axiosInstance';

export async function signOut() {
  try {
    const response = await axiosInstance.post('/auth/signout', {}, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Clear all storage
    localStorage.clear();
    sessionStorage.clear();

    return response;
  } catch (error: unknown) {

    // Still clear storage even if the API call fails
    localStorage.clear();
    sessionStorage.clear();
    
    throw error;
  }
}