type LogLevel = 'debug' | 'info' | 'warn' | 'error';
type Environment = 'development' | 'sandbox' | 'staging' | 'production';

interface LoggerOptions {
  metadata?: unknown;
}

// Production logger - does nothing
const productionLogger = {
  debug: () => {},
  info: () => {},
  warn: () => {},
  error: () => {}
};

// Full implementation logger for non-production environments
const debugLogger = (() => {
  class Logger {
    private static instance: Logger;
    private initialized: boolean = false;

    constructor() {
      this.initialized = true;
      console.debug('Debug Logger Initialized');
    }

    static getInstance(): Logger {
      if (!Logger.instance) {
        Logger.instance = new Logger();
      }
      return Logger.instance;
    }

    debug(message: string, options: LoggerOptions = {}) {
      if (!this.initialized) {
        console.warn('Logger not properly initialized');
        return;
      }
      console.debug(`[DEBUG] ${message}`, options.metadata || '');
    }

    info(message: string, options: LoggerOptions = {}) {
      if (!this.initialized) {
        console.warn('Logger not properly initialized');
        return;
      }
      console.log(`[INFO] ${message}`, options.metadata || '');
    }

    warn(message: string, options: LoggerOptions = {}) {
      if (!this.initialized) {
        console.warn('Logger not properly initialized');
        return;
      }
      console.warn(`[WARN] ${message}`, options.metadata || '');
    }

    error(message: string, options: LoggerOptions = {}) {
      if (!this.initialized) {
        console.warn('Logger not properly initialized');
        return;
      }
      console.error(`[ERROR] ${message}`, options.metadata || '');
    }
  }

  return Logger.getInstance();
})();

// Helper function to determine if environment should have logging
const shouldEnableLogging = (env: string | undefined): boolean => {
  console.debug('Checking if logging should be enabled:', {
    providedEnv: env,
    processEnv: process.env,
    nodeEnv: process.env.NODE_ENV,
    reactAppEnv: process.env.REACT_APP_ENV
  });

  if (!env) {
    console.warn('No environment provided to shouldEnableLogging');
    return false;
  }

  const loggingEnvironments: Environment[] = ['development', 'sandbox', 'staging'];
  const shouldLog = loggingEnvironments.includes(env as Environment);
  
  console.debug('Logger Environment Check Result:', {
    currentEnv: env,
    shouldLog,
    loggingEnvironments
  });
  
  return shouldLog;
};

// Export the appropriate logger based on environment
const selectedLogger = shouldEnableLogging(process.env.REACT_APP_ENV)
  ? debugLogger
  : productionLogger;

console.debug('Final Logger Selection:', {
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  isDebugLogger: selectedLogger === debugLogger,
  isProductionLogger: selectedLogger === productionLogger
});

export const logger = selectedLogger; 