import { addBusinessDays, isBusinessDay } from './dateUtils';

export const getEarliestStartDate = (): Date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  // Add 3 full business days
  return addBusinessDays(today, 3);
};

export const getLatestStartDate = (): Date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const result = new Date(today);
  result.setDate(result.getDate() + 60);
  return result;
};

export const getLatestEndDate = (startDate: Date): Date => {
  const date = new Date(startDate);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 14);
  return date;
};

export const formatDateForInput = (date: Date): string => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    console.error('Invalid date provided to formatDateForInput:', date);
    return '';
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const isValidStartDate = (date: Date): boolean => {
  const earliest = getEarliestStartDate();
  const latest = getLatestStartDate();
  return date >= earliest && date <= latest && isBusinessDay(date);
}; 