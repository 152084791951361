import React from 'react';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import styles from './DragDropOverlay.module.css';

interface DragDropOverlayProps {
  isVisible: boolean;
  title?: string;
  subtitle?: string;
}

const DragDropOverlay: React.FC<DragDropOverlayProps> = ({
  isVisible,
  title = "Add Files to Your Case",
  subtitle = "Drop your images, PDFs, Word documents, or CSV files here"
}) => {
  if (!isVisible) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <ArrowUpTrayIcon className={styles.icon} />
        <h2 className={styles.title}>
          {title}
        </h2>
        <p className={styles.text}>
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default DragDropOverlay; 