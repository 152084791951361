import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import logo from '../assets/images/llr_logo_footer.png';
import { COMPANY_NAME, COPYRIGHT_YEAR, PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '../constants';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.leftSide}>
            <Link to="/support" className={styles.logoLink}>
              <img src={logo} alt={`${COMPANY_NAME} Logo`} className={styles.logo} />
            </Link>
          </div>
          <div className={styles.rightSide}>
            <nav className={styles.footerNav}>
              <a href={PRIVACY_POLICY_URL} className={styles.footerLink} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
              <a href={TERMS_OF_USE_URL} className={styles.footerLink} target="_blank" rel="noopener noreferrer">Terms of Use</a>
              <span className={styles.copyright}>&copy; {COPYRIGHT_YEAR} {COMPANY_NAME}</span>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;