import React from 'react';
import { useAuth } from '../contexts/AuthContext';

interface RoleGuardProps {
  requiredRole: string | string[];
  children: React.ReactNode;
}

export const RoleGuard: React.FC<RoleGuardProps> = ({ requiredRole, children }) => {
  const { hasRole } = useAuth();

  if (!hasRole(requiredRole)) {
    return null;
  }

  return <>{children}</>;
}; 