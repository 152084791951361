import React from 'react';
import styles from './PageHeader.module.css';

interface PageHeaderProps {
  title: string;
  variant?: 'small' | 'large';
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, variant = 'small' }) => {
  if (variant === 'large') {
    return (
      <div className={styles.largeHeader}>
        <h1 className={styles.largeTitle}>{title}</h1>
      </div>
    );
  }

  return (
    <div className={styles.smallHeader}>
      <h1 className={styles.smallTitle}>{title}</h1>
      <div className={styles.dividerContainer}>
        <div className={styles.dividerLine}></div>
        <div className={styles.dividerHighlight}></div>
      </div>
    </div>
  );
};

export default PageHeader;