import axios from 'axios';
import { logger } from '@/utils/logger';

export const forgotPassword = async (email: string) => {
  logger.info('Initiating password reset for email:', { metadata: { email } });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/forgot-password`,
      { email }
    );
    logger.debug('Password reset API response:', { metadata: response.data });
    return response.data;
  } catch (error) {
    logger.error('Error in password reset API call:', { metadata: error });
    throw error;
  }
};