import axios from 'axios';
import { logger } from '@/utils/logger';

export const resetPassword = async (
  email: string,
  confirmationCode: string,
  newPassword: string
) => {
  try {
    logger.info('Resetting password for email:', { metadata: { email } });
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/reset-password`,
      {
        email,
        confirmationCode,
        newPassword
      }
    );
    logger.debug('Reset password API response:', { metadata: response.data });
    return response.data;
  } catch (error) {
    logger.error('Error resetting password:', { metadata: error });
    throw error;
  }
};
