import React, { useState } from 'react';
import Layout from '../components/Layout';
import styles from './Settings.module.css';
import useThemeColors from '../hooks/useThemeColors';
import PageHeader from '../components/PageHeader';
import { forgotPassword } from '../api/auth/forgotPassword';
import { resetPassword } from '../api/auth/resetPassword';
import { Helmet } from 'react-helmet-async';
import { APP_TITLE_SUFFIX } from '../constants';

const themeColors = {
  yellow: '#FED141',
  orange: '#FF9D6E',
  pink: '#F67599',
  fuchsia: '#DD7FD3',
  purple: '#9595D2',
  blue: '#8BB8E8',
  green: '#64CCC9',
  grey: '#88888D',
};

type SettingsSection = 'account' | 'theme';

const Settings: React.FC = () => {
  const [activeSection, setActiveSection] = useState<SettingsSection>('account');
  const { selectedColor, setSelectedColor } = useThemeColors();
  
  const [email, setEmail] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetMessage, setResetMessage] = useState('');

  const handleRequestCode = async () => {
    try {
      await forgotPassword(email);
      setShowCodeInput(true);
      setResetMessage('Verification code sent to your email');
    } catch (error) {
      setResetMessage('Error sending verification code');
    }
  };

  const handleResetPassword = async () => {
    try {
      await resetPassword(email, confirmationCode, newPassword);
      setResetMessage('Password successfully reset');
      setShowCodeInput(false);
      setEmail('');
      setConfirmationCode('');
      setNewPassword('');
    } catch (error) {
      setResetMessage('Error resetting password');
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'account':
        return (
          <div>
            <h2 className={styles.sectionTitle}>Account Settings</h2>
            <div className={styles.passwordReset}>
              <h3>Reset Password</h3>
              {resetMessage && <p className={styles.message}>{resetMessage}</p>}
              
              <div className={styles.formGroup}>
                <label>Email Address</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={styles.input}
                />
                {!showCodeInput && (
                  <button 
                    onClick={handleRequestCode}
                    className={styles.button}
                    disabled={!email}
                  >
                    Request Code
                  </button>
                )}
              </div>

              {showCodeInput && (
                <>
                  <div className={styles.formGroup}>
                    <label>Verification Code</label>
                    <input
                      type="text"
                      value={confirmationCode}
                      onChange={(e) => setConfirmationCode(e.target.value)}
                      className={styles.input}
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label>New Password</label>
                    <input
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className={styles.input}
                    />
                  </div>
                  <button 
                    onClick={handleResetPassword}
                    className={styles.button}
                    disabled={!confirmationCode || !newPassword}
                  >
                    Reset Password
                  </button>
                </>
              )}
            </div>
          </div>
        );
      case 'theme':
        return (
          <div>
            <h2 className={styles.sectionTitle}>Theme Settings</h2>
            <div className={styles.colorPicker}>
              {Object.entries(themeColors).map(([name, color]) => (
                <div
                  key={name}
                  className={styles.colorSquare}
                  style={{ backgroundColor: color }}
                  onClick={() => setSelectedColor(color)}
                />
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Settings | {APP_TITLE_SUFFIX}</title>
      </Helmet>
      <div className={`w-full max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:px-12 ${styles.container}`}>
        <PageHeader title="Settings" variant="large" />
        <div className={styles.mainContainer}>
          <div className={styles.settingsLayout}>
            <div className={styles.sidebar}>
              <button
                className={styles.sectionButton}
                onClick={() => setActiveSection('account')}
              >
                Account
              </button>
              <button
                className={styles.sectionButton}
                onClick={() => setActiveSection('theme')}
              >
                Theme
              </button>
            </div>
            <div className={styles.content}>
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;