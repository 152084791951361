// List of US federal holidays (can be expanded as needed)
const US_HOLIDAYS = [
  '2024-01-01', // New Year's Day
  '2024-01-15', // Martin Luther King Jr. Day
  '2024-02-19', // Presidents' Day
  '2024-05-27', // Memorial Day
  '2024-06-19', // Juneteenth
  '2024-07-04', // Independence Day
  '2024-09-02', // Labor Day
  '2024-10-14', // Columbus Day
  '2024-11-11', // Veterans Day
  '2024-11-28', // Thanksgiving Day
  '2024-12-25', // Christmas Day
  '2025-01-01', // New Year's Day
];

export function isWeekend(date: Date): boolean {
  const day = date.getDay();
  return day === 0 || day === 6; // 0 is Sunday, 6 is Saturday
}

export function isHoliday(date: Date): boolean {
  const dateString = date.toISOString().split('T')[0];
  return US_HOLIDAYS.includes(dateString);
}

export function isBusinessDay(date: Date): boolean {
  return !isWeekend(date) && !isHoliday(date);
}

export function addBusinessDays(date: Date, days: number): Date {
  let currentDate = new Date(date);
  let remainingDays = days;

  while (remainingDays > 0) {
    currentDate.setDate(currentDate.getDate() + 1);
    if (isBusinessDay(currentDate)) {
      remainingDays--;
    }
  }

  return currentDate;
} 