import React from 'react';
import styles from './LoaderDots.module.css';

export interface LoaderDotsProps {
  /**
   * Text that describes the current status and is only visible to screenreaders
   */
  assistiveText?: string;
  /**
   * Controls the size of the dots
   */
  size?: 'small' | 'medium';
  /**
   * Changes the dot colors
   */
  variant?: 'primary' | 'white';
}

const LoaderDots: React.FC<LoaderDotsProps> = ({
  assistiveText = 'Loading',
  size = 'medium',
  variant = 'primary'
}) => {
  const dotClassName = `${styles.dot} ${styles[`dot--${size}`]} ${styles[`dot--${variant}`]}`;

  return (
    <ul className={styles.container} role="status">
      <li className={dotClassName} />
      <li className={dotClassName} />
      <li className={dotClassName} />
      <li className={styles.assistiveText}>{assistiveText}</li>
    </ul>
  );
};

export default LoaderDots; 