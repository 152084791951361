import axios, { AxiosHeaders } from 'axios';
import { refreshToken } from './auth/refreshToken';
import { logger } from '@/utils/logger';
import { isLocalDev } from '@/utils/environment';
import { developmentAuth } from '@/utils/developmentAuth';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  headers: new AxiosHeaders({
    'Content-Type': 'application/json',
  }),
  timeout: 30000,
});

// Add request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers) {
      config.headers = new AxiosHeaders();
    }

    config.headers.set('Accept', 'application/json');
    
    // Add development auth headers if in development
    if (isLocalDev()) {
      const credentials = developmentAuth.getCredentials();
      if (credentials) {
        const auth = btoa(`${credentials.username}:${credentials.password}`);
        config.headers.set('Authorization', `Basic ${auth}`);
        logger.debug('Added development auth headers');
      } else {
        logger.warn('No development credentials available');
      }
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Update response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response?.status === 401 && !originalRequest._retry) {
      // Don't retry if this was already a proactive refresh attempt
      if (originalRequest.headers['X-Proactive-Refresh']) {
        throw error;
      }

      originalRequest._retry = true;
      try {
        logger.info('Begin refresh token due to 401');
        await refreshToken();
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        logger.error('Failed to refresh token', { metadata: refreshError });
        throw refreshError;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;