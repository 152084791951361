import axiosInstance from '@/api/axiosInstance';
import { logger } from '@/utils/logger';
import { FundraiserListItem, FundraiserListResponse } from './types';

export const getFundraisers = async (): Promise<FundraiserListItem[]> => {
  try {
    const response = await axiosInstance.get<FundraiserListResponse>('/i/s/f');
    
    logger.info('Processed ' + response.data.data.records.length + ' fundraisers');

    // Transform the records to match our FundraiserListItem interface
    return response.data.data.records.map(record => ({
      fundraiserNumber: record.fundraiserNumber || '',
      fundraisingFor: record.fundraisingFor || '',
      startDate: record.startDate || '',
      endDate: record.endDate || '',
      status: record.status || ''
    }));

  } catch (error) {
    logger.error('Error fetching fundraisers:', { metadata: error });
    return [];
  }
};

// Re-export the type from types.ts
export type { FundraiserListItem } from './types';