import React, { useRef, useEffect } from 'react';
import styles from './CharacterLimitedTextField.module.css';

interface CharacterLimitedTextFieldProps {
  id: string;
  value: string;
  onChange: (value: string) => void;
  maxLength: number;
  required?: boolean;
  label?: string;
  className?: string;
  labelClassName?: string;
  minHeight?: string;
  resizable?: boolean;
  width?: string;
  placeholder?: string;
  noFocusOutline?: boolean;
}

const CharacterLimitedTextField: React.FC<CharacterLimitedTextFieldProps> = ({
  id,
  value,
  onChange,
  maxLength = 2500,
  required = false,
  label,
  className,
  minHeight = '150px',
  labelClassName,
  resizable = true,
  width = '100%',
  noFocusOutline = false,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  // Adjust height on value change
  useEffect(() => {
    adjustHeight();
  }, [value]);

  const getCharacterCountClass = (length: number) => {
    if (length > maxLength * 0.9) return styles.redText;
    if (length > maxLength * 0.8) return styles.orangeText;
    return '';
  };

  return (
    <div className={styles.textFieldContainer} style={{ width }}>
      {label && (
        <label htmlFor={id} className={labelClassName || styles.label}>
          {label}
        </label>
      )}
      <textarea
        ref={textareaRef}
        id={id}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        maxLength={maxLength}
        required={required}
        className={`${styles.textarea} ${!resizable ? styles.notResizable : ''} ${
          noFocusOutline ? styles.noFocusOutline : ''
        } ${className || ''}`}
        style={{ 
          minHeight,
          width: '100%',
        }}
        rows={1}
      />
      <div className={styles.characterCount}>
        <span className={getCharacterCountClass(value.length)}>
          {value.length}/{maxLength}
        </span>
      </div>
    </div>
  );
};

export default CharacterLimitedTextField; 