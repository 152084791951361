import { isLocalDev } from '@/utils/environment';
import { logger } from '@/utils/logger';
import axios from 'axios';
import { UserDetails } from '@/api/auth/getUser';

interface AuthResponse {
  isAuthenticated: boolean;
  user: UserDetails | null;
}

interface SignInResponse {
  challengeName?: 'NEW_PASSWORD_REQUIRED';
  session?: string;
  message: string;
}

// Production version (will be the only code included in prod/staging/sandbox)
const productionAuth = {
  getCredentials: () => null,
  authenticate: async () => ({ isAuthenticated: false, user: null }),
  getUser: async () => null
};

// Development version (gets tree-shaken in production/staging/sandbox)
const developmentAuthImpl = {
  getCredentials: () => {
    const username = process.env.REACT_APP_DEV_USERNAME;
    const password = process.env.REACT_APP_DEV_PASSWORD;
    
    if (!username || !password) {
      logger.warn('Development credentials not found in .env.development.local');
      return null;
    }

    return { username, password };
  },
  
  authenticate: async (): Promise<AuthResponse> => {
    const credentials = developmentAuthImpl.getCredentials();
    if (!credentials) return { isAuthenticated: false, user: null };

    try {
      // For development, return a mock user with role
      return {
        isAuthenticated: true,
        user: {
          email: credentials.username || 'dev@example.com',
          firstName: 'Development',
          lastName: 'User',
          role: 'admin'
        }
      };
    } catch (error) {
      logger.error('Development auth failed:', { metadata: error });
      return { isAuthenticated: false, user: null };
    }
  }
};

// At build time, this is simplified to just productionAuth
export const developmentAuth = isLocalDev() ? developmentAuthImpl : productionAuth; 