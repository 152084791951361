import axiosInstance from '@/api/axiosInstance';
import { logger } from '@/utils/logger';

export interface CaseAttachment {
  id: string;
  title: string;
  fileType: string;
  fileExtension: string;
  size: number;
  downloadUrl: string;
}

export interface AttachmentsResponse {
  success: boolean;
  caseId: string;
  totalAttachments: number;
  attachments: CaseAttachment[];
}

export const getCaseAttachments = async (caseId: string): Promise<CaseAttachment[]> => {
  logger.info('Fetching attachments for case:', { metadata: { caseId } });
  
  try {
    const response = await axiosInstance.get<AttachmentsResponse>(
      `/i/s/c/${caseId}/attachments`
    );

    if (!response.data.success) {
      logger.error('API returned success: false');
      throw new Error('Failed to fetch attachments');
    }

    logger.info('Total attachments found:', { 
      metadata: { count: response.data.totalAttachments } 
    });
    
    // Log each attachment's metadata
    response.data.attachments.forEach((attachment, index) => {
      logger.debug(`Attachment ${index + 1} metadata:`, {
        metadata: {
          id: attachment.id,
          title: attachment.title,
          fileType: attachment.fileType,
          downloadUrl: attachment.downloadUrl
        }
      });
    });

    // Log the full URLs that will be used for downloading
    logger.debug('Full download URLs:', {
      metadata: response.data.attachments.map((attachment, index) => ({
        index: index + 1,
        url: `${axiosInstance.defaults.baseURL}${attachment.downloadUrl}`
      }))
    });

    return response.data.attachments;
  } catch (error) {
    logger.error('Error fetching case attachments:', {
      metadata: {
        name: error instanceof Error ? error.name : 'Unknown error',
        message: error instanceof Error ? error.message : 'Unknown error message',
        stack: error instanceof Error ? error.stack : 'No stack trace'
      }
    });
    
    throw new Error(
      error instanceof Error 
        ? error.message 
        : 'Failed to fetch case attachments'
    );
  }
};

export const downloadAttachment = async (attachment: CaseAttachment): Promise<Blob> => {
  logger.info('Starting download for attachment:', {
    metadata: {
      id: attachment.id,
      title: attachment.title,
      url: attachment.downloadUrl
    }
  });
  
  const fullUrl = attachment.downloadUrl;
  logger.debug('Making download request to:', { metadata: { url: fullUrl } });
  
  try {
    const response = await axiosInstance.get(fullUrl, {
      responseType: 'blob'
    });
    
    logger.info('Download successful:', {
      metadata: {
        title: attachment.title,
        size: `${(response.data.size / 1024).toFixed(2)} KB`,
        type: response.data.type
      }
    });
    
    return response.data;
  } catch (error) {
    logger.error('Error downloading attachment:', {
      metadata: {
        attachmentId: attachment.id,
        title: attachment.title,
        error: error instanceof Error ? error.message : 'Unknown error'
      }
    });
    
    throw new Error(
      error instanceof Error 
        ? error.message 
        : 'Failed to download attachment'
    );
  }
};
