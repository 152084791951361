type Environment = 'development' | 'sandbox' | 'staging' | 'production';

// For local development features
export const isLocalDev = () => {
  return process.env.REACT_APP_ENV === 'development';
};

// For sandbox environment
export const isSandboxEnv = () => {
  return process.env.REACT_APP_ENV === 'sandbox';
};

// For staging environment
export const isStagingEnv = () => {
  return process.env.REACT_APP_ENV === 'staging';
};

// For development-like features (either local dev or sandbox)
export const isDevelopmentLike = () => {
  return isLocalDev() || isSandboxEnv();
}; 