import { AxiosError } from 'axios';
import axiosInstance from '@/api/axiosInstance';
import { logger } from '@/utils/logger';

// For reactive refresh (401 responses)
export async function refreshToken() {
  logger.info('Attempting token refresh due to 401 response...');
  try {
    const response = await axiosInstance.post('/auth/refresh', {}, {
      withCredentials: true,
    });

    if (response.status === 200) {
      logger.info('Token refresh successful');
      return response.data;
    } else {
      logger.warn(`Unexpected status during token refresh: ${response.status}`);
      throw new Error(`Unexpected status: ${response.status}`);
    }
  } catch (error) {
    logger.error('Token refresh failed:', { metadata: error });
    throw error;
  }
}

// For proactive refresh (before expiration)
export async function proactiveTokenRefresh() {
  logger.info('Proactively refreshing token...');
  try {
    const response = await axiosInstance.post('/auth/refresh', {}, {
      withCredentials: true,
    });

    if (response.status === 200) {
      logger.info('Proactive token refresh successful');
      return response.data;
    }
    
    throw new Error(`Unexpected status: ${response.status}`);
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 401) {
      logger.warn('Token expired during proactive refresh - triggering new auth check');
      return { status: 'needs_reauth' };
    }
    
    logger.error('Proactive token refresh failed:', { metadata: error });
    throw error;
  }
} 