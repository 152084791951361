import React, { ReactNode, useState, useEffect } from 'react';
import Header from './Header';
import HeaderSub from './HeaderSub';
import Breadcrumbs from './Breadcrumbs';
import Footer from './Footer';
import Announcement from './Announcement';
import EnvironmentBanner from '../internal/components/EnvironmentBanner';
import styles from './Layout.module.css';
import { isLocalDev, isSandboxEnv, isStagingEnv } from '@/utils/environment';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [announcement, setAnnouncement] = useState<string | null>(null);
  const showEnvironmentBanner = isLocalDev() || isSandboxEnv() || isStagingEnv();

  useEffect(() => {
    // Fetch the announcement from localStorage or an API
    const storedAnnouncement = localStorage.getItem('announcement');
    if (storedAnnouncement) {
      setAnnouncement(storedAnnouncement);
    }
  }, []);

  // Only add padding when environment banner is actually shown
  const layoutStyle = showEnvironmentBanner ? { paddingTop: '32px' } : undefined;

  return (
    <div className={`${styles.layout} ${announcement ? styles.withAnnouncement : ''}`} style={layoutStyle}>
      <EnvironmentBanner />
      {announcement && <Announcement message={announcement} />}
      <Header />
      <HeaderSub />
      <Breadcrumbs />
      <main className={styles.main}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;