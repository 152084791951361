import React from 'react';
import { Address, USState, Country } from '../api/platform/integrations/salesforce/fundraiser/types';
import styles from '../pages/NewFundraiser.module.css';

interface AddressFormProps {
  address: Address;
  onChange: (address: Address) => void;
  className?: string;
  isAPO?: boolean;
  onAPOChange?: (isAPO: boolean) => void;
}

const US_STATES: USState[] = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
  'AA', 'AE', 'AP'
];

const COUNTRIES: Country[] = ['United States', 'Canada'];

const AddressForm: React.FC<AddressFormProps> = ({ 
  address, 
  onChange, 
  className,
  isAPO = false,
  onAPOChange
}) => {
  const handleChange = (field: keyof Address, value: string | boolean) => {
    const updatedAddress = {
      ...address,
      [field]: value
    };
    onChange(updatedAddress);
  };

  const handleAPOChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onAPOChange) {
      onAPOChange(e.target.checked);
      if (e.target.checked) {
        onChange({
          ...address,
          state: 'AE',
          country: 'United States',
          isAPO: true
        });
      }
    }
  };

  return (
    <div className={`${styles.addressForm} ${className || ''}`}>
      <div className={styles.formGroup}>
        <label className={styles.checkboxLabel}>
          <input
            type="checkbox"
            checked={isAPO}
            onChange={handleAPOChange}
            className={styles.checkbox}
          />
          <span className={styles.checkboxText}>This is an APO / FPO / DPO Address</span>
        </label>
      </div>

      <div className={styles.formGroup}>
        <label className={styles.formLabel}>Address</label>
        <input
          type="text"
          value={address.street}
          onChange={(e) => handleChange('street', e.target.value)}
          className={styles.input}
          required
        />
      </div>

      <div className={styles.formGroup}>
        <label className={styles.formLabel}>Address 2 (Optional)</label>
        <input
          type="text"
          value={address.address2 || ''}
          onChange={(e) => handleChange('address2', e.target.value)}
          className={styles.input}
        />
      </div>

      <div className={styles.addressFields}>
        <div className={styles.cityField}>
          <label className={styles.formLabel}>City</label>
          <input
            type="text"
            value={address.city}
            onChange={(e) => handleChange('city', e.target.value)}
            className={styles.input}
            required
          />
        </div>

        <div className={styles.stateField}>
          <label className={styles.formLabel}>State</label>
          <select
            value={address.state || ''}
            onChange={(e) => handleChange('state', e.target.value as USState)}
            className={styles.select}
            required
          >
            <option value="">Select a state</option>
            {isAPO ? (
              <>
                <option value="AA">Armed Forces Americas (AA)</option>
                <option value="AE">Armed Forces Europe (AE)</option>
                <option value="AP">Armed Forces Pacific (AP)</option>
              </>
            ) : (
              US_STATES.filter(state => !['AA', 'AE', 'AP'].includes(state))
                .map(state => (
                  <option key={state} value={state}>{state}</option>
                ))
            )}
          </select>
        </div>

        <div className={styles.zipField}>
          <label className={styles.formLabel}>ZIP Code</label>
          <input
            type="text"
            value={address.zipCode}
            onChange={(e) => {
              if (/^\d{0,5}$/.test(e.target.value)) {
                handleChange('zipCode', e.target.value);
              }
            }}
            className={styles.input}
            required
            maxLength={5}
            pattern="\d{5}"
          />
        </div>

        <div className={styles.countryField}>
          <label className={styles.formLabel}>Country</label>
          <select
            value={address.country}
            onChange={(e) => handleChange('country', e.target.value as Country)}
            className={styles.select}
            required
            disabled={isAPO}
          >
            <option value="">Select a country</option>
            {COUNTRIES.map(country => (
              <option key={country} value={country}>{country}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default AddressForm; 