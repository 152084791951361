import React from 'react';
import styles from './Announcement.module.css';

interface AnnouncementProps {
  message: string;
}

const Announcement: React.FC<AnnouncementProps> = ({ message }) => {
  return (
    <div className={styles.announcement}>
      <span className="text-primary-site-color font-bold">{message}</span> {/* Apply Tailwind classes */}
    </div>
  );
};

export default Announcement;

/*
TODO: Ideas for announcements will help with db table design.
- Add a close button to the announcement
- Add a link to the announcement
- Add a date to the announcement
- Add a time to the announcement
- Add a location to the announcement
- Add a type to the announcement
- Add a priority to the announcement
- Add a status to the announcement
- abillity to have multiple announcements showing too?
*/