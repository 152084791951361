import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import logo from '../assets/images/llr_logo.png';
import ProfileDropdown from './ProfileDropdown';
import ProfileAvatar from './ProfileAvatar';
import styles from './Header.module.css';

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Get initials and name even if no user info
  const initials = user ? `${user.firstName[0]}${user.lastName[0]}`.toUpperCase() : 'LR';
  const fullName = user ? `${user.firstName} ${user.lastName}` : 'LuLaRoe User';
  const email = user?.email || '';

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.content}>
          <Link to="/support" className={styles.logoLink}>
            <img src={logo} alt="Logo" className={styles.logo} />
            <span className={styles.supportText}>Home</span>
          </Link>
          <div className={styles.profileContainer} ref={dropdownRef}>
            <ProfileAvatar 
              initials={initials}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)} 
            />
            {isDropdownOpen && (
              <div className={styles.dropdownWrapper}>
                <ProfileDropdown 
                  user={{
                    name: fullName,
                    initials: initials,
                    email: email
                  }} 
                  onClose={() => setIsDropdownOpen(false)} 
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;