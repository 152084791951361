import axiosInstance from '@/api/axiosInstance';
import { FundraiserDetail, FundraiserDetailResponse } from './types';

export const getFundraiser = async (fundraiserId: string): Promise<FundraiserDetail> => {
  try {
    const response = await axiosInstance.get<FundraiserDetailResponse>(`/i/s/f/${fundraiserId}`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching fundraiser:', error);
    throw error;
  }
};
