import React from 'react';
import Avatar from './Avatar';
import styles from '../pages/CaseDetail.module.css';

interface MessageItemProps {
  user: string;
  date: string;
  message: string;
  initials: string;
  firstName: string;
  lastName?: string;
}

const MessageItem: React.FC<MessageItemProps> = ({ 
  user, 
  date, 
  message, 
  initials, 
  firstName,
  lastName 
}) => {
  const displayInitials = lastName === '(LuLaRoe Support)' 
    ? firstName[0]
    : lastName 
      ? `${firstName[0]}${lastName[0]}`
      : firstName[0];
  
  const displayName = lastName ? `${firstName} ${lastName}` : firstName;

  return (
    <div className={styles.activityItem}>
      <Avatar initials={displayInitials} size="medium" />
      <div className={styles.activityContent}>
        <div className={styles.activityHeader}>
          <p>
            <span className={styles.activityUser}>{displayName}</span>{' '}
            <span className={styles.activityDate}>{date}</span>
          </p>
        </div>
        <p className={styles.activityMessage} dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </div>
  );
};

export default MessageItem;