import React from 'react';
import styles from '../styles/EnvironmentBanner.module.css';
import { isLocalDev, isSandboxEnv, isStagingEnv } from '@/utils/environment';

const EnvironmentBanner: React.FC = () => {
  // Don't render anything in production
  if (!isLocalDev() && !isSandboxEnv() && !isStagingEnv()) {
    return null;
  }

  const getEnvironmentInfo = () => {
    if (isLocalDev()) {
      return {
        text: 'Development Environment',
        className: styles.development
      };
    }
    if (isSandboxEnv()) {
      return {
        text: 'Sandbox Environment',
        className: styles.sandbox
      };
    }
    if (isStagingEnv()) {
      return {
        text: 'Staging Environment',
        className: styles.staging
      };
    }
    return null;
  };

  const envInfo = getEnvironmentInfo();
  if (!envInfo) return null;

  return (
    <div className={`${styles.banner} ${envInfo.className}`}>
      {envInfo.text}
    </div>
  );
};

export default EnvironmentBanner; 