import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import SupportMessageBar from '../components/SupportMessageBar';
import PageHeader from '../components/PageHeader';
import { MagnifyingGlassIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import styles from './Cases.module.css';
import { getCases, Case as SFCase, SortField, SortOrder } from '../api/platform/integrations/salesforce/case/getCases';
import { getCase, CaseDetail as ICaseDetail } from '../api/platform/integrations/salesforce/case/getCase';
import { Helmet } from 'react-helmet-async';
import { APP_TITLE_SUFFIX } from '../constants';
import { useData } from '../contexts/DataContext';

// Define the constant for maximum cases to display
const MAX_CASES_PER_PAGE = 7;

const Cases: React.FC = () => {
  const navigate = useNavigate();
  const { cases, refreshCases, isInitialLoading } = useData();
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState<SortField>('date');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [statusFilter, setStatusFilter] = useState<'ALL' | 'OPEN' | 'IN PROGRESS' | 'CLOSED' | 'BLOCKED'>('ALL');
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Move the strictEnsureMaxCases function definition up here
  const strictEnsureMaxCases = (cases: (SFCase | null)[]) => {
    const validCases = cases.filter((supportCase): supportCase is SFCase => supportCase !== null);
    const limitedCases = validCases.slice(0, MAX_CASES_PER_PAGE);
    const emptyRows = Array(Math.max(0, MAX_CASES_PER_PAGE - limitedCases.length)).fill(null);
    return [...limitedCases, ...emptyRows];
  };

  // Only handle browser refresh
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refreshCases().catch(err => {
          console.error('Error refreshing cases:', err);
          setError(err instanceof Error ? err.message : 'Failed to refresh cases');
        });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refreshCases]);

  const filteredCases = useMemo(() => {
    return cases.filter(supportCase => 
      (statusFilter === 'ALL' || supportCase.status === statusFilter) &&
      (supportCase.reference.toLowerCase().includes(searchQuery.toLowerCase()) ||
       supportCase.summary.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }, [cases, statusFilter, searchQuery]);

  const sortedCases = useMemo(() => {
    return [...filteredCases].sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredCases, sortField, sortOrder]);

  const totalPages = Math.ceil(sortedCases.length / MAX_CASES_PER_PAGE);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(Math.max(1, totalPages));
    }
  }, [currentPage, totalPages]);

  const paginatedCases = useMemo(() => {
    const startIndex = (currentPage - 1) * MAX_CASES_PER_PAGE;
    const slicedCases = sortedCases.slice(startIndex, startIndex + MAX_CASES_PER_PAGE);
    return strictEnsureMaxCases(slicedCases);
  }, [sortedCases, currentPage]);

  const safeDisplayedCases = useMemo(() => {
    return strictEnsureMaxCases(paginatedCases);
  }, [paginatedCases]);

  if (isInitialLoading) {
    return null;
  }

  const handleRowClick = async (caseReference: string) => {
    try {
      const caseDetail = await getCase(caseReference);
      navigate(`/cases/${caseReference}`, { 
        state: { caseDetail }
      });
    } catch (error) {
      console.error('Error fetching case details:', error);
    }
  };

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const getPageNumbers = (currentPage: number, totalPages: number) => {
    const pageNumbers = [];
    const maxPagesToShow = 7;
    const sidePages = 3;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= sidePages + 1) {
        for (let i = 1; i <= sidePages + 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - sidePages) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - (sidePages + 1); i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  return (
    <Layout>
      <Helmet>
        <title>Cases | {APP_TITLE_SUFFIX}</title>
      </Helmet>
      <div className={`w-full max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:px-12 ${styles.container}`}>
        <PageHeader title="Support Cases" variant="large" />

        {/* Use the new SupportMessageBar component */}
        <SupportMessageBar />

        {error && (
          <div className="bg-red-50 text-red-700 p-4 rounded-md mb-4">
            {error}
          </div>
        )}

        <div className={styles.filterSection}>
          <select
            className={styles.select}
            onChange={(e) => setStatusFilter(e.target.value as 'ALL' | 'OPEN' | 'IN PROGRESS' | 'CLOSED' | 'BLOCKED')}
            value={statusFilter}
          >
            <option value="ALL" className="select-none">All Cases</option>
            <option value="OPEN" className="select-none">Open Cases</option>
            <option value="IN PROGRESS" className="select-none">In Progress Cases</option>
            <option value="CLOSED" className="select-none">Closed Cases</option>
            <option value="BLOCKED" className="select-none">Blocked Cases</option>
          </select>
          <div className={styles.searchContainer}>
            <MagnifyingGlassIcon className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search cases..."
              className={styles.searchInput}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <colgroup>
                <col className={styles.colCase} />
                <col className={styles.colSummary} />
                <col className={styles.colType} />
                <col className={styles.colStatus} />
                <col className={styles.colDate} />
              </colgroup>
              <thead className={styles.tableHeader}>
                <tr>
                  {[
                    { field: 'reference', label: 'Case' },
                    { field: 'summary', label: 'Summary' },
                    { field: 'requestType', label: 'Case Type' },
                    { field: 'status', label: 'Status' },
                    { field: 'date', label: 'Open Date' },
                  ].map(({ field, label }) => (
                    <th
                      key={field}
                      className={styles.tableHeaderCell}
                      onClick={() => handleSort(field as SortField)}
                    >
                      <div className="flex items-center">
                        {label}
                        <ChevronUpDownIcon className={styles.sortIcon} />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {safeDisplayedCases.slice(0, MAX_CASES_PER_PAGE).map((supportCase, index) => (
                  supportCase ? (
                    <tr 
                      key={supportCase.reference} 
                      className={styles.tableRow}
                      onClick={() => handleRowClick(supportCase.reference)}
                    >
                      <td className={styles.tableCell}>
                        <span className="text-primary-site-color font-medium">
                          {supportCase.reference}
                        </span>
                      </td>
                      <td className={styles.tableCell}>
                        <div className={styles.summaryText}>{supportCase.summary}</div>
                      </td>
                      <td className={styles.tableCell}>
                        <div className={styles.requestTypeText}>{supportCase.requestType}</div>
                      </td>
                      <td className={styles.tableCell}>
                        <span className={`${styles.statusBadge} ${styles[supportCase.status.toLowerCase().replace(' ', '_')]}`}>
                          {supportCase.status.replace('_', ' ')}
                        </span>
                      </td>
                      <td className={styles.tableCell}>
                        {supportCase.date.toLocaleDateString()}
                      </td>
                    </tr>
                  ) : (
                    <tr key={`empty-${index}`} className="h-12">
                      <td colSpan={5}></td>
                    </tr>
                  )
                ))}
              </tbody>
            </table>
          </div>

          {totalPages > 1 && (
            <div className="mt-0"> 
              <nav className="flex justify-center select-none" aria-label="Pagination">
                <button
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  className={`${styles.paginationButton} ${currentPage === 1 ? styles.disabledButton : styles.activeButton}`}
                  disabled={currentPage === 1}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                {getPageNumbers(currentPage, totalPages).map((pageNumber, index, array) => (
                  pageNumber === '...' ? (
                    <span key={`ellipsis-${index}`} className={styles.ellipsis}>
                      ...
                    </span>
                  ) : (
                    <button
                      key={pageNumber}
                      onClick={() => setCurrentPage(pageNumber as number)}
                      className={`${styles.paginationButton} ${currentPage === pageNumber ? styles.currentPage : styles.activeButton}`}
                    >
                      {pageNumber}
                    </button>
                  )
                ))}
                <button
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                  className={`${styles.paginationButton} ${currentPage === totalPages ? styles.disabledButton : styles.activeButton}`}
                  disabled={currentPage === totalPages}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Cases;