// Company Information
export const COMPANY_NAME = "LuLaRoe";
export const COMPANY_SLOGAN = "Simply Comfortable";
export const COMPANY_WEBSITE = "https://www.lularoe.com";

// Application Names and Titles
export const APP_TITLE_SUFFIX = "LuLaRoe Home";

// Support Information
export const SUPPORT_PHONE = "951-737-7875";
export const SUPPORT_EMAIL = "support@lularoe.com";

// Legal
export const PRIVACY_POLICY_URL = "https://www.lularoe.com/policies/privacy-policy"; // TODO: Replace with actual URL when available
export const TERMS_OF_USE_URL = "https://www.lularoe.com/policies/terms-of-service"; // TODO: Replace with actual URL when available

// Other constants
export const COPYRIGHT_YEAR = new Date().getFullYear();

// Subheader Links
export const BUILD_URL = "https://home.mylularoe.com/www/login";
export const BLESS_URL = "https://www.lularoebless.com/Account/SignIn";
export const SHOPIFY_URL = "https://shopify.com/68957929777/account?locale=en&region_country=US";
export const FASHION_FUN_URL = "https://fashionfun.lularoe.com/";
export const FUNDRAISERS_URL = "#"; // TODO: Replace with actual URL when available
export const KNOWLEDGE_URL = "#"; // TODO: Replace with actual URL when available