import React, { useState, useRef, useEffect, DragEvent, useCallback, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { PaperClipIcon, CheckCircleIcon, PauseCircleIcon, ArrowUpCircleIcon, DocumentIcon, XMarkIcon, ChevronLeftIcon, ChevronRightIcon, ExclamationCircleIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';  // Changed from solid to outline
import Layout from '../components/Layout';
import SupportMessageBar from '../components/SupportMessageBar';
import Avatar from '../components/Avatar'; // Update this import
import SectionHeader from '../components/SectionHeader'; // Add this import
import MessageItem from '../components/MessageItem'; // Add this import
import styles from './CaseDetail.module.css';
import { getCase, CaseDetail as ICaseDetail } from '../api/platform/integrations/salesforce/case/getCase';
import { closeCase, addComment, reopenCase } from '../api/platform/integrations/salesforce/case/updateCase';
import CharacterLimitedTextField from '../components/CharacterLimitedTextField';
import ConfirmDialog from '../components/ConfirmDialog';
import { attachFilesToCase, validateFiles } from '../api/platform/integrations/salesforce/case/attachFile';
import { CaseAttachment } from '../api/platform/integrations/salesforce/case/getCaseAttachments';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { APP_TITLE_SUFFIX } from '../constants';
import { Tooltip } from '../components/Tooltip'; // You'll need to create this component if it doesn't exist
import { logger } from '@/utils/logger';
import DragDropOverlay from '../components/DragDropOverlay';
import { useDragAndDrop } from '../hooks/useDragAndDrop';

interface Activity {
  user: string;
  date: string;
  message: string;
}

interface FilePreview {
  id: string;
  file: File;
  previewUrl: string;
  uploadStatus: 'pending' | 'uploading' | 'success' | 'error';
  error?: string;
  fileType: string;
  title: string;
  downloadUrl: string;
  timestamp: number;
  uploadProgress: number;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const isWithinTenDays = (createdDate: Date): boolean => {
  const now = new Date();
  const tenDaysAgo = new Date(now.setDate(now.getDate() - 10));
  return new Date(createdDate) > tenDaysAgo;
};

const IMAGE_TYPES: readonly string[] = [
  'image/jpeg', 
  'image/png', 
  'image/jpg', 
  'image/PNG',
  'image/JPEG',
  'image/JPG',
  'PNG',
  'png'
];

const CaseDetail = () => {
  const { caseId } = useParams<{ caseId: string }>();
  const location = useLocation();
  const [caseDetail, setCaseDetail] = useState<ICaseDetail | null>(
    location.state?.caseDetail || null
  );
  const [isLoading, setIsLoading] = useState(!location.state?.caseDetail);
  const [error, setError] = useState<string | null>(null);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [newComment, setNewComment] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [filePreviews, setFilePreviews] = useState<FilePreview[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const [isHoldingFiles, setIsHoldingFiles] = useState(false);
  const [isCloseDialogOpen, setIsCloseDialogOpen] = useState(false);
  const [processedAttachments, setProcessedAttachments] = useState<string[]>([]);
  const { user } = useAuth();
  const [slideDirection, setSlideDirection] = useState<'left' | 'right' | null>(null);
  const [transitioningImageIndex, setTransitioningImageIndex] = useState<number | null>(null);

  const fileTypeCache = useRef(new Map<string, boolean>());

  const isImageFile = useCallback((fileType: string): boolean => {
    if (fileTypeCache.current.has(fileType)) {
      return fileTypeCache.current.get(fileType)!;
    }

    const result = IMAGE_TYPES.includes(fileType) || 
                  fileType.toLowerCase().includes('png') || 
                  fileType.toLowerCase().includes('image');

    fileTypeCache.current.set(fileType, result);

    if (process.env.NODE_ENV === 'development') {
      console.log('New file type check:', {
        fileType,
        isImage: result,
        cacheSize: fileTypeCache.current.size
      });
    }

    return result;
  }, []);

  const handleFiles = async (files: File[]) => {
    const validationError = validateFiles(files);
    if (validationError) {
      logger.error('File validation error:', { metadata: validationError });
      return;
    }

    // Create new previews
    const newPreviews: FilePreview[] = files.map(file => ({
      id: Math.random().toString(36).substr(2, 9),
      file,
      previewUrl: URL.createObjectURL(file),
      uploadStatus: 'uploading' as const,
      uploadProgress: 0,
      fileType: file.type,
      title: file.name,
      downloadUrl: '',
      timestamp: Date.now() + files.indexOf(file)
    }));

    // Add new previews to state
    setFilePreviews(prev => [...prev, ...newPreviews].sort((a, b) => a.timestamp - b.timestamp));

    // Upload files
    if (caseDetail) {
      try {
        const results = await attachFilesToCase(caseDetail.id, files);
        logger.debug('Upload results:', { metadata: results });
        
        // Update previews with success status
        setFilePreviews(prev => 
          prev.map(preview => {
            const matchingResult = results.find(r => r.name === preview.title);
            if (matchingResult) {
              return { 
                ...preview, 
                uploadStatus: matchingResult.success ? 'success' as const : 'error' as const,
                error: matchingResult.error,
                uploadProgress: matchingResult.success ? 100 : 0
              };
            }
            return preview;
          }).sort((a, b) => a.timestamp - b.timestamp)
        );

        // Refresh case to get new attachments
        await fetchAndUpdateCase();

      } catch (error) {
        logger.error('Error uploading files:', { metadata: error });
        
        // Update previews with error status
        setFilePreviews(prev => 
          prev.map(preview => {
            const matchingNewPreview = newPreviews.find(p => p.id === preview.id);
            return matchingNewPreview
              ? { 
                  ...preview, 
                  uploadStatus: 'error' as const,
                  error: error instanceof Error ? error.message : 'Upload failed',
                  uploadProgress: 0
                }
              : preview;
          }).sort((a, b) => a.timestamp - b.timestamp)
        );
      }
    }
  };

  const { isDraggingOverDocument } = useDragAndDrop({
    onDrop: handleFiles
  });

  const fetchAndUpdateCase = async () => {
    if (!caseId) return;
    
    try {
      setError(null);
      const updatedCase = await getCase(caseId);
      
      // If we have existing case details, compare and update only if there are changes
      if (caseDetail) {
        const hasChanges = JSON.stringify(updatedCase) !== JSON.stringify(caseDetail);
        if (hasChanges) {
          logger.debug('Case details updated from server');
          setCaseDetail(updatedCase);
        }
      } else {
        setCaseDetail(updatedCase);
      }
    } catch (err) {
      logger.error('Error fetching case:', { metadata: err });
      setError(err instanceof Error ? err.message : 'Failed to load case details');
    } finally {
      setIsLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchAndUpdateCase();
  }, [caseId]);

  // Refresh on window focus
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchAndUpdateCase();
      }
    };

    const handleFocus = () => {
      fetchAndUpdateCase();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, [caseId, caseDetail]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (selectedImageIndex !== null) {
        if (e.key === 'ArrowLeft') {
          navigateImage('prev');
        } else if (e.key === 'ArrowRight') {
          navigateImage('next');
        } else if (e.key === 'Escape') {
          closeImageModal();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedImageIndex]);

  const navigateImage = (direction: 'prev' | 'next') => {
    if (selectedImageIndex === null || !imagePreviews.length) return;
    
    const newIndex = direction === 'prev' 
      ? (selectedImageIndex - 1 + imagePreviews.length) % imagePreviews.length
      : (selectedImageIndex + 1) % imagePreviews.length;
    
    // Add safety check
    if (newIndex < 0 || newIndex >= imagePreviews.length) {
      logger.error('Invalid image index:', { metadata: { newIndex, totalImages: imagePreviews.length }});
      return;
    }
    
    setTransitioningImageIndex(selectedImageIndex);
    setSlideDirection(direction === 'prev' ? 'left' : 'right');
    setSelectedImageIndex(newIndex);
    
    setTimeout(() => {
      setTransitioningImageIndex(null);
      setSlideDirection(null);
    }, 300);
  };

  const closeImageModal = () => {
    setSelectedImageIndex(null);
  };

  useEffect(() => {
    if (caseDetail?.attachments?.length) {
      const attachmentIds = caseDetail.attachments.map(a => a.id).sort().join(',');
      const currentProcessedIds = processedAttachments.sort().join(',');
      
      if (attachmentIds !== currentProcessedIds) {
        logger.debug('Processing new attachments');
        const previews = caseDetail.attachments.map(attachment => {
          const fullDownloadUrl = attachment.downloadUrl.startsWith('http') 
            ? attachment.downloadUrl
            : `${API_BASE_URL}${attachment.downloadUrl}`;
          
          const downloadUrlWithFilename = `${fullDownloadUrl}?filename=${encodeURIComponent(attachment.title)}`;
          
          return {
            id: attachment.id,
            file: new File([], attachment.title, { type: attachment.fileType }),
            previewUrl: fullDownloadUrl,
            downloadUrl: downloadUrlWithFilename,
            uploadStatus: 'success' as const,
            fileType: attachment.fileType,
            title: attachment.title,
            timestamp: Date.now(),
            uploadProgress: 100
          };
        });
        
        setFilePreviews(previews);
        setProcessedAttachments(caseDetail.attachments.map(a => a.id));
      } else {
        logger.debug('Attachments already processed, skipping conversion');
      }
    }
  }, [caseDetail?.attachments, processedAttachments]);

  const addNewComment = async () => {
    if (!newComment.trim() || !caseDetail) return;
    
    try {
      await addComment(caseDetail.id, newComment);
      
      // Refresh the case to get the new comment
      const updatedCase = await getCase(caseDetail.reference);
      setCaseDetail(updatedCase);
      
      // Clear the comment input
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addNewComment();
    }
  };

  const handleSubmitComment = async () => {
    if (!newComment.trim() || !caseDetail) return;
    
    try {
      // If case is closed but within 10 days, reopen it
      if (caseDetail.status === 'CLOSED' && isWithinTenDays(caseDetail.createdDate)) {
        await reopenCase(caseDetail.id);
      }

      // Add the comment
      await addComment(caseDetail.id, newComment);
      setNewComment(''); // Clear the comment input immediately
      
      // Fetch the updated case details
      await fetchAndUpdateCase();
    } catch (error) {
      console.error('Error adding comment:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsHoldingFiles(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const relatedTarget = e.relatedTarget as Node;
    if (!e.currentTarget.contains(relatedTarget)) {
      setIsHoldingFiles(false);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isHoldingFiles) {
      setIsHoldingFiles(true);
    }
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsHoldingFiles(false);

    const files = Array.from(e.dataTransfer.files);
    const remainingSlots = 10 - filePreviews.length;
    
    if (remainingSlots <= 0) {
      alert('Maximum number of files (10) has been reached');
      return;
    }
    
    if (files.length > remainingSlots) {
      alert(`You can only add ${remainingSlots} more file${remainingSlots === 1 ? '' : 's'}`);
      return;
    }
    
    handleFiles(files);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      const remainingSlots = 10 - filePreviews.length;
      
      if (remainingSlots <= 0) {
        alert('Maximum number of files (10) has been reached');
        e.target.value = '';
        return;
      }
      
      if (newFiles.length > remainingSlots) {
        alert(`You can only add ${remainingSlots} more file${remainingSlots === 1 ? '' : 's'}`);
        e.target.value = '';
        return;
      }
      
      handleFiles(newFiles);
    }
  };

  const openImageModal = useCallback((index: number) => {
    setSelectedImageIndex(index);
  }, []);

  const handleCloseCase = async () => {
    setIsCloseDialogOpen(true);
  };

  const confirmCloseCase = async () => {
    if (!caseDetail) return;
    
    try {
      await closeCase(caseDetail.id);
      setCaseDetail({
        ...caseDetail,
        status: 'CLOSED'
      });
      setIsCloseDialogOpen(false);
    } catch (error) {
      logger.error('Error closing case:', { metadata: error });
    }
  };

  // Memoize the filtered image previews more effectively
  const imagePreviews = useMemo(() => {
    const filtered = filePreviews.filter(preview => isImageFile(preview.fileType));
    return filtered;
  }, [filePreviews, isImageFile]);

  // Then define handlePreviewClick before it's used
  const handlePreviewClick = useCallback(async (preview: FilePreview) => {
    if (isImageFile(preview.fileType)) {
      const imagePreviews = filePreviews.filter(p => isImageFile(p.fileType));
      const imageIndex = imagePreviews.findIndex(p => p.id === preview.id);
      openImageModal(imageIndex);
    } else {
      try {
        const response = await fetch(preview.previewUrl, {
          method: 'GET',
          credentials: 'include'
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const filename = preview.title.toLowerCase().endsWith('.pdf') 
          ? preview.title 
          : `${preview.title}.pdf`;
        
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        window.URL.revokeObjectURL(url);
        
      } catch (error) {
        console.error('Download failed:', error);
      }
    }
  }, [filePreviews, isImageFile, openImageModal]);

  // Update the container click handler to be simpler too
  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Add this new function
  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Add this new function to handle thumbnail clicks
  const handleThumbnailClick = (newIndex: number) => {
    if (selectedImageIndex === null) return;
    
    // Determine if we're moving left or right
    const direction = newIndex > selectedImageIndex ? 'next' : 'prev';
    
    setTransitioningImageIndex(selectedImageIndex);
    setSlideDirection(direction === 'prev' ? 'left' : 'right');
    setSelectedImageIndex(newIndex);
    
    setTimeout(() => {
      setTransitioningImageIndex(null);
      setSlideDirection(null);
    }, 300);
  };

  // Update renderFilePreviews to use memoization
  const renderFilePreviews = useCallback(() => {
    return (
      <div className={styles.filePreviews}>
        {filePreviews.map((preview, index) => (
          <div 
            key={preview.id} 
            className={styles.filePreview}
            onClick={() => handlePreviewClick(preview)}
          >
            {isImageFile(preview.fileType) ? (
              <img 
                src={preview.previewUrl}
                alt={preview.title} 
                className={styles.previewImage}
              />
            ) : (
              <div className={styles.fileExtensionPreview}>
                {getFileExtension(preview.title)}
              </div>
            )}
            {preview.uploadStatus === 'uploading' && (
              <div className={styles.uploadProgressOverlay}>
                <svg className={styles.progressCircle} viewBox="0 0 36 36">
                  <path
                    d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="rgba(255, 255, 255, 0.4)"
                    strokeWidth="3"
                  />
                  <path
                    d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="3"
                    strokeDasharray={`${preview.uploadProgress}, 100`}
                  />
                </svg>
                <span className={styles.progressText}>
                  {preview.uploadProgress}%
                </span>
              </div>
            )}
            {preview.uploadStatus === 'error' && (
              <div className={styles.errorOverlay}>
                <ExclamationCircleIcon className={styles.errorIcon} />
                <span className={styles.errorText}>
                  {preview.error || 'Upload failed'}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }, [filePreviews, handlePreviewClick, isImageFile]);

  // Add helper function to get file extension
  const getFileExtension = (filename: string): string => {
    return filename.split('.').pop()?.toUpperCase() || '';
  };

  if (!caseDetail) {
    return (
      <Layout>
        <div className="text-center p-4">
          Case not found
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <Helmet>
        <title>
          {caseDetail 
            ? `Case ${caseDetail.reference} | ${APP_TITLE_SUFFIX}`
            : `Case Detail | ${APP_TITLE_SUFFIX}`
          }
        </title>
      </Helmet>
      <div className={styles.container}>
        <SupportMessageBar />
        <div className={styles.mainContent}>
          <div className={styles.subContainer}>
            <div className={styles.leftContainer}>
              {/* Description Section - Hide for Damage Cases */}
              {caseDetail.subType !== 'Damage Cases' && (
                <div className={`${styles.section} ${styles.descriptionSection}`}>
                  <SectionHeader title="Description" />
                  <p className={styles.detailsContent}>{caseDetail.description}</p>
                </div>
              )}

              {/* Mobile Details Section */}
              <div className={`${styles.section} ${styles.mobileDetailsSection}`}>
                <SectionHeader title="Details" />
                <div className={styles.detailsGrid}>
                  {caseDetail.reference !== 'Unknown' && (
                    <div>
                      <p><strong>Case Number:</strong></p>
                      <p className={styles.detailsContent}>{caseDetail.reference}</p>
                    </div>
                  )}
                  {caseDetail.type !== 'Unknown' && (
                    <div>
                      <p><strong>Type:</strong></p>
                      <p className={styles.detailsContent}>{caseDetail.type}</p>
                    </div>
                  )}
                  {caseDetail.subType !== 'Unknown' && (
                    <div>
                      <p><strong>Sub Type:</strong></p>
                      <p className={styles.detailsContent}>{caseDetail.subType}</p>
                    </div>
                  )}
                  {caseDetail.subCategory !== 'Unknown' && (
                    <div>
                      <p><strong>Sub Category:</strong></p>
                      <p className={styles.detailsContent}>{caseDetail.subCategory}</p>
                    </div>
                  )}
                  {caseDetail.status && (
                    <div>
                      <p><strong>Status:</strong></p>
                      <p className={styles.detailsContent}>{caseDetail.status}</p>
                    </div>
                  )}
                  {caseDetail.subType === 'Damage Cases' && (
                    <div className={styles.rmaContainer}>
                      <Tooltip content="Your RMA number will appear within 24 hours. Please contact Home Office Support if there are any issues.">
                        <p><strong>RMA Number:</strong></p>
                        <p className={styles.detailsContent}>
                          {caseDetail.netsuiteRma || 'Pending'}
                        </p>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>

              {/* File Upload Section */}
              <div className={`${styles.section} ${styles.attachmentSection}`}>
                <SectionHeader title="Attachments" />
                
                {/* File Upload Area */}
                {caseDetail.status !== 'CLOSED' && (
                  <div
                    className={`${styles.fileUpload} ${isHoldingFiles ? styles.dragging : ''}`}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={triggerFileInput}
                  >
                    <div className={styles.fileUploadContent}>
                      <PaperClipIcon className={styles.paperclipIcon} />
                      <span className={styles.fileUploadText}>
                        Drop files here or{' '}
                        <span className={styles.browseLink}>
                          browse
                        </span>
                      </span>
                      <input
                        ref={fileInputRef}
                        type="file"
                        onChange={handleFileChange}
                        className="hidden"
                        multiple
                        accept=".jpg,.jpeg,.png,.gif,.bmp,.webp,.pdf,.csv"
                      />
                    </div>
                  </div>
                )}

                {/* File Previews */}
                {filePreviews.length > 0 ? (
                  renderFilePreviews()
                ) : (
                  <p className={styles.noAttachment}>No attachments yet</p>
                )}
              </div>

              {/* Activity Section */}
              <div className={`${styles.section} ${caseDetail.subType === 'Damage Cases' ? styles.damageActivitySection : ''}`}>
                <SectionHeader title="Activity" />
                <div className={styles.activityList}>
                  {caseDetail.comments.length > 0 ? (
                    caseDetail.comments
                      .filter(comment => comment.body !== caseDetail.description)
                      .sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime())
                      .map((comment) => (
                        <MessageItem
                          key={comment.id}
                          user={comment.createdBy.fullName}
                          date={comment.createdDate.toLocaleString()}
                          message={comment.body}
                          firstName={comment.createdBy.firstName}
                          lastName={comment.createdBy.lastName}
                          initials={comment.createdBy.lastName 
                            ? `${comment.createdBy.firstName[0]}${comment.createdBy.lastName[0]}`
                            : comment.createdBy.firstName[0]
                          }
                        />
                      ))
                  ) : (
                    <p className={styles.noActivity}>No comments found</p>
                  )}
                  
                  {/* Only hide comment field for damage cases */}
                  {caseDetail.subType !== 'Damage Cases' && (
                    <div className={styles.headerActions}>
                      <Avatar 
                        initials={user ? `${user.firstName[0]}${user.lastName[0]}`.toUpperCase() : 'LR'}
                        size="medium" 
                      />
                      <div style={{ width: 'calc(100% - 48px)' }}>
                        <CharacterLimitedTextField
                          id="comment"
                          value={newComment}
                          onChange={setNewComment}
                          maxLength={2500}
                          className={styles.commentField}
                          minHeight="50px"
                          resizable={false}
                          width="100%"
                          noFocusOutline={true}
                        />
                        <div className={styles.commentActions}>
                          <button 
                            className={styles.cancelButton}
                            onClick={() => setNewComment('')}
                            type="button"
                          >
                            Cancel
                          </button>
                          <button 
                            className={styles.addCommentButton}
                            onClick={handleSubmitComment}
                            type="button"
                            disabled={!newComment.trim()}
                          >
                            Add Comment
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Show message only for damage cases */}
                  {caseDetail.subType === 'Damage Cases' && (
                    <div className={styles.damageNoteMessage}>
                      Comments are disabled for damage cases
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Desktop Details Section */}
            <div className={styles.desktopRightContainer}>
              <SectionHeader title="Details" />
              <div className={styles.detailsGrid}>
                {caseDetail.reference !== 'Unknown' && (
                  <div>
                    <p><strong>Case Number:</strong></p>
                    <p className={styles.detailsContent}>{caseDetail.reference}</p>
                  </div>
                )}
                {caseDetail.type !== 'Unknown' && (
                  <div>
                    <p><strong>Type:</strong></p>
                    <p className={styles.detailsContent}>{caseDetail.type}</p>
                  </div>
                )}
                {caseDetail.subType !== 'Unknown' && (
                  <div>
                    <p><strong>Sub Type:</strong></p>
                    <p className={styles.detailsContent}>{caseDetail.subType}</p>
                  </div>
                )}
                {caseDetail.subCategory !== 'Unknown' && (
                  <div>
                    <p><strong>Sub Category:</strong></p>
                    <p className={styles.detailsContent}>{caseDetail.subCategory}</p>
                  </div>
                )}
                {caseDetail.status && (
                  <div>
                    <p><strong>Status:</strong></p>
                    <p className={styles.detailsContent}>{caseDetail.status}</p>
                  </div>
                )}
                {caseDetail.subType === 'Damage Cases' && (
                  <div className={styles.rmaContainer}>
                    <Tooltip content="Your RMA number will appear within 24 hours. Please contact Home Office Support if there are any issues.">
                      <p><strong>RMA Number:</strong></p>
                      <p className={styles.detailsContent}>
                        {caseDetail.netsuiteRma || 'Pending'}
                      </p>
                    </Tooltip>
                  </div>
                )}
              </div>
              
              {/* Add back the close case button */}
              {caseDetail.status !== 'CLOSED' && (
                <div className={styles.actions}>
                  <button
                    onClick={() => setIsCloseDialogOpen(true)}
                    className={`${styles.actionItem} text-red-600`}
                  >
                    <CheckCircleIcon className={styles.actionIcon} />
                    Close Case
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Image Preview Modal */}
      {selectedImageIndex !== null && (
        <div className={styles.modalOverlay}>
          {/* Only show prev button if there's a previous image */}
          {selectedImageIndex > 0 && imagePreviews[selectedImageIndex - 1] && (
            <button 
              onClick={() => navigateImage('prev')}
              className={`${styles.navButton} ${styles.navButtonLeft}`}
            >
              <ChevronLeftIcon className="h-6 w-6" />
            </button>
          )}

          <div className={styles.modalContent}>
            {transitioningImageIndex !== null && imagePreviews[transitioningImageIndex] && (
              <img 
                src={imagePreviews[transitioningImageIndex].previewUrl} 
                alt="Previous Preview" 
                className={`${styles.modalImage} ${
                  slideDirection === 'left' ? styles.slideOutRight : 
                  slideDirection === 'right' ? styles.slideOutLeft : ''
                }`}
              />
            )}
            {imagePreviews[selectedImageIndex] && (
              <img 
                src={imagePreviews[selectedImageIndex].previewUrl} 
                alt="Preview" 
                className={`${styles.modalImage} ${
                  slideDirection === 'left' ? styles.slideInLeft : 
                  slideDirection === 'right' ? styles.slideInRight : ''
                }`}
              />
            )}
            <button 
              onClick={closeImageModal}
              className={styles.closeButton}
            >
              <div className={styles.closeButtonCircle}>
                <XMarkIcon className="h-6 w-6" />
              </div>
            </button>
          </div>

          {/* Only show next button if there's a next image */}
          {selectedImageIndex < imagePreviews.length - 1 && imagePreviews[selectedImageIndex + 1] && (
            <button 
              onClick={() => navigateImage('next')}
              className={`${styles.navButton} ${styles.navButtonRight}`}
            >
              <ChevronRightIcon className="h-6 w-6" />
            </button>
          )}

          <div className={styles.modalThumbnails}>
            {imagePreviews.map((preview: FilePreview, index: number) => (
              <div 
                key={preview.id}
                className={`${styles.modalThumbnail} ${
                  index === selectedImageIndex ? styles.modalThumbnailActive : ''
                }`}
                onClick={() => handleThumbnailClick(index)}
              >
                {index !== selectedImageIndex && (
                  <div className={styles.thumbnailOverlay} />
                )}
                <img 
                  src={preview.previewUrl} 
                  alt={preview.title} 
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <ConfirmDialog
        isOpen={isCloseDialogOpen}
        onClose={() => setIsCloseDialogOpen(false)}
        onConfirm={confirmCloseCase}
        title="Close Case"
        message="Are you sure you want to close this case? This will mark the case as resolved."
        confirmText="Close Case"
        cancelText="Cancel"
        confirmButtonClass="bg-primary-site-color hover:bg-primary-site-color-hover"
      />

      <DragDropOverlay isVisible={isDraggingOverDocument} />
    </Layout>
  );
};

export default CaseDetail;