import React from 'react';

interface AvatarProps {
  initials?: string;
  imageUrl?: string;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large';
}

const Avatar: React.FC<AvatarProps> = ({ initials, imageUrl, onClick, size = 'medium' }) => {
  const sizeClasses = {
    small: 'w-6 h-6 text-xs',
    medium: 'w-8 h-8 text-sm',
    large: 'w-10 h-10 text-base'
  };

  return (
    <div 
      className={`rounded-full bg-gray-100 flex items-center justify-center cursor-pointer ${sizeClasses[size]}`}
      onClick={onClick}
    >
      {imageUrl ? (
        <img src={imageUrl} alt="Avatar" className="w-full h-full rounded-full object-cover" />
      ) : (
        <span className="text-gray-600 font-medium select-none">{initials}</span>
      )}
    </div>
  );
};

export default Avatar;