import React from 'react';
import LoaderDots from '../LoaderDots';
import styles from './Button.module.css';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Button contents
   */
  children: React.ReactNode;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * Is the button in a loading state?
   */
  isLoading?: boolean;
  /**
   * Button variant
   */
  variant?: 'primary' | 'secondary' | 'outline' | 'submit';
  /**
   * Button size
   */
  size?: 'small' | 'medium' | 'large' | 'submit';
  /**
   * Full width button
   */
  fullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  isLoading = false,
  variant = 'primary',
  size = 'medium',
  fullWidth = false,
  disabled,
  className,
  ...props
}) => {
  const buttonClassName = `
    ${styles.button}
    ${styles[`button--${variant}`]}
    ${styles[`button--${size}`]}
    ${fullWidth ? styles['button--full-width'] : ''}
    ${className || ''}
  `.trim();

  return (
    <button
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading ? (
        <LoaderDots 
          size={size === 'small' ? 'small' : 'medium'}
          variant={['primary', 'submit'].includes(variant) ? 'white' : 'primary'}
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Button; 