import axiosInstance from '@/api/axiosInstance';
import { logger } from '@/utils/logger';

// Configuration
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const MAX_FILES_PER_CASE = 10;
const ALLOWED_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',
  'application/pdf',
  'text/csv'
];

export interface UploadResult {
  name: string;
  success: boolean;
  contentVersionId?: string;
  contentDocumentId?: string;
  error?: string;
}

export interface UploadProgress {
  fileName: string;
  progress: number;
}

export const validateFiles = (files: File[]): string | null => {
  logger.debug('Validating files:', {
    metadata: {
      fileCount: files.length,
      fileNames: files.map(f => f.name)
    }
  });

  if (files.length > MAX_FILES_PER_CASE) {
    return `Maximum ${MAX_FILES_PER_CASE} files allowed per case`;
  }

  for (const file of files) {
    if (!ALLOWED_MIME_TYPES.includes(file.type)) {
      return `File type not allowed: ${file.name}. Only images (JPG, PNG, GIF, BMP, WEBP), PDF, and CSV files are allowed.`;
    }

    if (file.size > MAX_FILE_SIZE) {
      return `File too large: ${file.name}. Maximum size is 5MB`;
    }
  }

  return null;
};

interface AttachFileResponse {
  success: boolean;
  results: {
    name: string;
    success: boolean;
    contentVersionId: string;
    contentDocumentId?: string;
    size: string;
    type: string;
    error?: string;
  }[];
  filesProcessed: number;
  successfulUploads: number;
  failedUploads: number;
}

export const attachFilesWithProgress = async (
  caseId: string, 
  files: File[],
  onProgress?: (progress: UploadProgress[]) => void
): Promise<UploadResult[]> => {
  logger.debug('Starting file upload with progress:', {
    metadata: {
      caseId,
      fileCount: files.length,
      fileNames: files.map(f => f.name)
    }
  });

  const validationError = validateFiles(files);
  if (validationError) {
    logger.error('File validation failed:', {
      metadata: { error: validationError }
    });
    throw new Error(validationError);
  }

  const formData = new FormData();
  files.forEach(file => {
    formData.append('files', file);
  });

  try {
    const response = await axiosInstance.post<AttachFileResponse>(
      `/i/s/c/${caseId}/file-attach`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        },
        withCredentials: true,
        timeout: 60000, // 60 seconds for larger uploads
        onUploadProgress: (progressEvent) => {
          if (onProgress && progressEvent.total) {
            const totalProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            
            // Create progress data for each file
            const progressData = files.map(file => ({
              fileName: file.name,
              progress: totalProgress
            }));

            logger.debug('Upload progress:', {
              metadata: {
                totalProgress,
                filesProgress: progressData
              }
            });

            onProgress(progressData);
          }
        }
      }
    );

    logger.debug('Upload completed:', {
      metadata: {
        success: response.data.success,
        results: response.data.results
      }
    });

    return response.data.results.map(result => ({
      name: result.name,
      success: result.success,
      contentVersionId: result.contentVersionId,
      contentDocumentId: result.contentDocumentId,
      error: result.error
    }));
  } catch (error) {
    logger.error('File upload error:', {
      metadata: {
        error,
        caseId,
        fileCount: files.length
      }
    });
    throw error;
  }
};

// Keep this for backward compatibility
export const attachFilesToCase = attachFilesWithProgress;