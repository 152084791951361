import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import { MagnifyingGlassIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import styles from './Fundraisers.module.css';
import { getFundraisers } from '../api/platform/integrations/salesforce/fundraiser/getFundraisers';
import { FundraiserListItem } from '../api/platform/integrations/salesforce/fundraiser/types';
import PageHeader from '../components/PageHeader';
import { useData } from '../contexts/DataContext';

type FundraiserStatus = 'ALL' | 'REQUEST_RECEIVED' | 'PENDING_ACTION' | 'INELIGIBLE' | 'APPROVED' | 'CANCELLED' | 'PENDING_FINALIZATION' | 'FINAL_DETAILS_RECEIVED' | 'PENDING_CHECK_VERIFICATION' | 'FINAL_DETAILS_VERIFIED' | 'FINALIZED' | 'EXPIRED';

const statusOptions: FundraiserStatus[] = [
  'ALL',
  'REQUEST_RECEIVED',
  'PENDING_ACTION',
  'INELIGIBLE',
  'APPROVED',
  'CANCELLED',
  'PENDING_FINALIZATION',
  'FINAL_DETAILS_RECEIVED',
  'PENDING_CHECK_VERIFICATION',
  'FINAL_DETAILS_VERIFIED',
  'FINALIZED',
  'EXPIRED'
];

type SortField = 'fundraiserNumber' | 'fundraisingFor' | 'startDate' | 'endDate' | 'status';
type SortOrder = 'asc' | 'desc';

const Fundraisers: React.FC = () => {
  const navigate = useNavigate();
  const { fundraisers, refreshFundraisers, isInitialLoading } = useData();
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState<FundraiserStatus>('ALL');
  const [sortField, setSortField] = useState<SortField>('startDate');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

  useEffect(() => {
    console.log('Raw fundraisers:', fundraisers);
  }, [fundraisers]);

  const filteredAndSortedFundraisers = useMemo(() => {
    console.log('Filtering fundraisers:', {
      total: fundraisers.length,
      statusFilter,
      searchQuery
    });

    const filtered = fundraisers.filter(fundraiser => {
      const matchesStatus = statusFilter === 'ALL' || fundraiser.status === statusFilter;
      
      if (searchQuery.trim() === '') {
        return matchesStatus;
      }

      const searchLower = searchQuery.toLowerCase();
      const groupName = (fundraiser.fundraisingFor || '').toLowerCase();
      const reference = (fundraiser.fundraiserNumber || '').toLowerCase();
      
      const matchesSearch = 
        groupName.includes(searchLower) ||
        reference.includes(searchLower);
      
      return matchesStatus && matchesSearch;
    });

    console.log('After filtering:', {
      filteredCount: filtered.length
    });

    return [...filtered].sort((a, b) => {
      if (sortField === 'startDate' || sortField === 'endDate') {
        const dateA = new Date(a[sortField] || '').getTime();
        const dateB = new Date(b[sortField] || '').getTime();
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      }

      const valueA = String(a[sortField] || '').toLowerCase();
      const valueB = String(b[sortField] || '').toLowerCase();
      
      return sortOrder === 'asc' 
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });
  }, [fundraisers, statusFilter, searchQuery, sortField, sortOrder]);

  if (isInitialLoading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-site-color"></div>
        </div>
      </Layout>
    );
  }

  console.log('Rendering fundraisers:', {
    filtered: filteredAndSortedFundraisers.length,
    total: fundraisers.length
  });

  const handleCreateNew = () => {
    navigate('/fundraisers/new');
  };

  const handleRowClick = (fundraiser: FundraiserListItem) => {
    navigate(`/fundraisers/${fundraiser.fundraiserNumber}`);
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatStatus = (status: string) => {
    return status
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className="flex justify-between items-center mb-6">
          <PageHeader title="Fundraisers" variant="large" />
          <button
            onClick={handleCreateNew}
            className="bg-primary-site-color hover:bg-primary-site-color-hover text-white px-4 py-2 rounded-md text-sm font-medium"
          >
            Create New Fundraiser
          </button>
        </div>

        {error && (
          <div className="bg-red-50 text-red-700 p-4 rounded-md mb-4">
            {error}
          </div>
        )}

        <div className={styles.filterSection}>
          <select
            className={styles.select}
            onChange={(e) => setStatusFilter(e.target.value as FundraiserStatus)}
            value={statusFilter}
          >
            {statusOptions.map(status => (
              <option key={status} value={status}>
                {status === 'ALL' ? 'All Fundraisers' : formatStatus(status)}
              </option>
            ))}
          </select>
          <div className={styles.searchContainer}>
            <MagnifyingGlassIcon className={styles.searchIcon} />
            <input
              type="text"
              placeholder="Search fundraisers..."
              className={styles.searchInput}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <colgroup>
                <col className={styles.colFundraiser} />
                <col className={styles.colGroup} />
                <col className={styles.colStartDate} />
                <col className={styles.colEndDate} />
                <col className={styles.colStatus} />
              </colgroup>
              <thead className={styles.tableHeader}>
                <tr>
                  {[
                    { field: 'fundraiserNumber', label: 'Fundraiser' },
                    { field: 'fundraisingFor', label: 'Group Name' },
                    { field: 'startDate', label: 'Start Date' },
                    { field: 'endDate', label: 'End Date' },
                    { field: 'status', label: 'Status' },
                  ].map(({ field, label }) => (
                    <th
                      key={field}
                      className={styles.tableHeaderCell}
                      onClick={() => handleSort(field as SortField)}
                    >
                      <div className="flex items-center">
                        {label}
                        <ChevronUpDownIcon className={styles.sortIcon} />
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white">
                {filteredAndSortedFundraisers.map((fundraiser) => (
                  <tr
                    key={fundraiser.fundraiserNumber || 'unknown'}
                    className={styles.tableRow}
                    onClick={() => handleRowClick(fundraiser)}
                  >
                    <td className={styles.tableCell}>
                      <span className="text-primary-site-color font-medium">
                        {fundraiser.fundraiserNumber || 'N/A'}
                      </span>
                    </td>
                    <td className={styles.tableCell}>
                      <div className={styles.summaryText}>{fundraiser.fundraisingFor || 'N/A'}</div>
                    </td>
                    <td className={styles.tableCell}>
                      {fundraiser.startDate ? formatDate(fundraiser.startDate) : 'N/A'}
                    </td>
                    <td className={styles.tableCell}>
                      {fundraiser.endDate ? formatDate(fundraiser.endDate) : 'N/A'}
                    </td>
                    <td className={styles.tableCell}>
                      {fundraiser.status ? formatStatus(fundraiser.status) : 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Fundraisers;