import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { verifyAuth } from '@/api/auth';
import { getUser, UserDetails } from '@/api/auth/getUser';
import { isLocalDev } from '@/utils/environment';
import { logger } from '@/utils/logger';
import { developmentAuth } from '@/utils/developmentAuth';
import { proactiveTokenRefresh } from '@/api/auth/refreshToken';
import { AxiosError } from 'axios';

interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: UserDetails | null;
  hasRole: (requiredRole: string | string[]) => boolean;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isLoading: true,
  user: null,
  hasRole: () => false,
});

// Constants for token refresh (in milliseconds)
const REFRESH_INTERVAL = 25 * 60 * 1000; // 25 minutes

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<UserDetails | null>(null);
  const [refreshTimerId, setRefreshTimerId] = useState<NodeJS.Timeout | null>(null);

  // Function to handle token refresh
  const handleTokenRefresh = useCallback(async () => {
    try {
      const result = await proactiveTokenRefresh();
      
      // Check if we need to re-authenticate
      if (result?.status === 'needs_reauth') {
        // Verify auth instead of immediately signing out
        const isValid = await verifyAuth();
        if (isValid) {
          logger.debug('Re-authentication successful');
          // Reset timer for next refresh
          const newTimerId = setTimeout(handleTokenRefresh, REFRESH_INTERVAL);
          setRefreshTimerId(newTimerId);
          return;
        }
        // Only sign out if verify auth fails
        logger.warn('Re-authentication failed, signing out');
        setIsAuthenticated(false);
        setUser(null);
        if (refreshTimerId) {
          clearTimeout(refreshTimerId);
          setRefreshTimerId(null);
        }
        return;
      }

      logger.debug('Token refreshed successfully');
      // Reset timer for next refresh
      const newTimerId = setTimeout(handleTokenRefresh, REFRESH_INTERVAL);
      setRefreshTimerId(newTimerId);
      
    } catch (error) {
      logger.error('Failed to refresh token:', { metadata: error });
      // Only sign out for serious errors, not 401s
      if (!(error instanceof AxiosError) || error.response?.status !== 401) {
        setIsAuthenticated(false);
        setUser(null);
        if (refreshTimerId) {
          clearTimeout(refreshTimerId);
          setRefreshTimerId(null);
        }
      }
    }
  }, [refreshTimerId]);

  // Function to start token refresh timer
  const startRefreshTimer = useCallback(() => {
    if (refreshTimerId) {
      clearTimeout(refreshTimerId);
    }

    const timerId = setTimeout(handleTokenRefresh, REFRESH_INTERVAL);
    setRefreshTimerId(timerId);
    
    logger.debug('Token refresh timer started', {
      metadata: { interval: REFRESH_INTERVAL }
    });
  }, [handleTokenRefresh, refreshTimerId]);

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (refreshTimerId) {
        clearTimeout(refreshTimerId);
      }
    };
  }, [refreshTimerId]);

  // Separate effect for starting the timer when authentication changes
  useEffect(() => {
    if (isAuthenticated) {
      startRefreshTimer();
    }
  }, [isAuthenticated]); // Only depend on isAuthenticated

  const hasRole = useCallback((requiredRole: string | string[]) => {
    if (!user?.role) return false;
    const roles = Array.isArray(requiredRole) ? requiredRole : [requiredRole];
    return roles.includes(user.role);
  }, [user]);
    
  // Main auth check effect
  useEffect(() => {
    const checkAuth = async () => {
      try {
        logger.debug('Checking authentication', {
          metadata: {
            isLocalDev: isLocalDev(),
            env: process.env.REACT_APP_ENV
          }
        });

        if (isLocalDev()) {
          logger.debug('Using development auth');
          const { isAuthenticated: isDevAuth, user: devUser } = await developmentAuth.authenticate();
          
          if (!isDevAuth || !devUser) {
            logger.warn('Development credentials not properly configured');
            setIsAuthenticated(false);
            setUser(null);
            setIsLoading(false);
            return;
          }

          setIsAuthenticated(true);
          setUser(devUser);
          setIsLoading(false);
          return;
        }


        // Normal authentication flow for sandbox/staging/production
        const verifyResponse = await verifyAuth();

        const isValid = await verifyAuth();
        logger.debug('Auth verification result:', { metadata: { isValid }});
        
        if (verifyResponse.authenticated) {
          setIsAuthenticated(true);
          setUser({
            email: '', // Email comes from somewhere else if needed
            firstName: verifyResponse.user.firstName,
            lastName: verifyResponse.user.lastName,
            role: verifyResponse.role
          });
        } else {
          setIsAuthenticated(false);
          setUser(null);
        }
      } catch (error) {
        logger.error('Auth check failed:', { metadata: error });
        setIsAuthenticated(false);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []); // No dependencies needed for initial auth check

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      isLoading, 
      user,
      hasRole
    }}>
      {children}
    </AuthContext.Provider>
  );
}; 