import axiosInstance from '@/api/axiosInstance';
import { logger } from '@/utils/logger';
import { CaseDetail, mapStatus } from './getCase';
import { getCaseAttachments, CaseAttachment } from './getCaseAttachments';
import { AxiosError } from 'axios';
import { DamageItem } from '../types/damage';

export interface CreateCaseRequest {
  type: string;
  subType: string;
  subCategory: string;
  description: string;
  additionalFields?: Record<string, string | DamageItem[]>;
}

// Match the actual server response structure
interface SalesforceCase {
  Id: string;
  CaseNumber: string;
  AccountId: string;
  CreatedDate: string;
  Description: string;
  Status: string;
  Type: string;
  Sub_Type__c: string;
  Sub_Category__c: string | null;
  Subject: string;
  NetSuite_RMA__c?: string;
  RecordType: {
    Name: string;
  };
}

interface SalesforceComment {
  Id: string;
  ParentId: string;
  CommentBody: string;
  CreatedDate: string;
  CreatedById: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

interface ServerResponse {
  case: SalesforceCase;
  comments: SalesforceComment[];
}

export const createCase = async (request: CreateCaseRequest): Promise<CaseDetail> => {
  try {
    if (request.type === 'Apparel Services' && request.subType === 'Damage Cases') {
      // Verify the damage items are in the correct format
      const damageItems = request.additionalFields?.damageItems as DamageItem[];
      
      logger.debug('Verifying damage items format:', {
        metadata: {
          additionalFields: request.additionalFields,
          damageItemsArray: damageItems,
          sampleItem: damageItems?.[0],
          totalItems: damageItems?.length
        }
      });

      // Verify each item has all required fields
      damageItems?.forEach((item, index) => {
        logger.debug(`Damage item ${index + 1}:`, {
          metadata: {
            productName: item.productName,
            sku: item.sku,
            size: item.size,
            defectType: item.defectType,
            countryOfOrigin: item.countryOfOrigin,
            damageDescription: item.damageDescription
          }
        });
      });

      logger.debug('Final request payload:', {
        metadata: {
          type: request.type,
          subType: request.subType,
          additionalFields: {
            damageItems: damageItems
          }
        }
      });
    }

    const response = await axiosInstance.post<ServerResponse>('/i/s/c', request);
    
    const { case: record, comments } = response.data;

    const caseDetail: CaseDetail = {
      id: record.Id,
      accountId: record.AccountId,
      reference: record.CaseNumber,
      createdDate: new Date(record.CreatedDate),
      requestType: record.RecordType?.Name || 'Unknown',
      subject: record.Subject || 'No Subject',
      type: record.Type || 'Unknown',
      subType: record.Sub_Type__c || 'Unknown',
      subCategory: record.Sub_Category__c || 'Unknown',
      description: record.Description || 'No Description',
      status: mapStatus(record.Status || 'Open'),
      netsuiteRma: record.NetSuite_RMA__c,
      comments: comments.map(comment => ({
        id: comment.Id,
        body: comment.CommentBody,
        createdDate: new Date(comment.CreatedDate),
        createdBy: {
          id: comment.createdBy.id,
          firstName: comment.createdBy.firstName,
          lastName: comment.createdBy.lastName,
          fullName: `${comment.createdBy.firstName} ${comment.createdBy.lastName}`.trim()
        }
      })),
      attachments: []
    };

    // Fetch attachments if needed
    try {
      caseDetail.attachments = await getCaseAttachments(record.Id);
    } catch (attachmentError) {
      logger.error('Error fetching attachments:', { metadata: attachmentError });
      // Don't throw error, just keep empty attachments array
    }

    return caseDetail;
  } catch (error: unknown) {
    logger.error('Error creating case:', { metadata: error });
    
    if (error instanceof Error && 'response' in error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        logger.error('Error response:', {
          metadata: {
            status: axiosError.response.status,
            statusText: axiosError.response.statusText,
            data: axiosError.response.data
          }
        });
      }
    }
    
    throw new Error(
      error instanceof Error 
        ? error.message 
        : 'Failed to create case'
    );
  }
};
