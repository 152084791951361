import React, { lazy, Suspense, startTransition } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './utils/PrivateRoute';
import './index.css';
import useThemeColors from './hooks/useThemeColors';
import { DataProvider } from './contexts/DataContext';
import { RoleGuard } from './components/RoleGuard';

// Pages
import Support from './pages/Support';
import CaseDetail from './pages/CaseDetail';
import Cases from './pages/Cases';
import Settings from './pages/Settings';
import Knowledge from './pages/Knowledge';
import Fundraisers from './pages/Fundraisers';
import FundraiserDetail from './pages/FundraiserDetail';
import NewFundraiser from './pages/NewFundraiser';
import KnowledgeArticle from './pages/KnowledgeArticle';

// Preload the internal module
const InternalModuleLoader = lazy(() => {
  // Start loading as soon as the file is parsed
  return import(/* webpackChunkName: "internal" */ './internal/InternalModuleLoader');
});

function App() {
  const { isLoaded } = useThemeColors();

  if (!isLoaded) {
    return null;
  }

  return (
    <HelmetProvider>
      <Router>
        <AuthProvider>
          <DataProvider>
            <div className="App min-h-screen w-full relative overflow-x-hidden">
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  {/* Root path now has an immediate check */}
                  <Route 
                    path="/" 
                    element={
                      <PrivateRoute>
                        <Navigate to="/support" replace />
                      </PrivateRoute>
                    } 
                  />

                  {/* Protected Routes */}
                  <Route path="/support" element={<PrivateRoute><Support /></PrivateRoute>} />
                  <Route path="/cases" element={<PrivateRoute><Cases /></PrivateRoute>} />
                  <Route path="/cases/:caseId" element={<PrivateRoute><CaseDetail /></PrivateRoute>} />
                  <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                  <Route path="/fundraisers" element={<PrivateRoute><Fundraisers /></PrivateRoute>} />
                  <Route path="/fundraisers/new" element={<PrivateRoute><NewFundraiser /></PrivateRoute>} />
                  <Route path="/fundraisers/:fundraiserNumber" element={<PrivateRoute><FundraiserDetail /></PrivateRoute>} />

                  {/* Internal Routes - Only loaded if user has correct role */}
                  <Route 
                    path="/internal/*" 
                    element={
                      <PrivateRoute>
                        <RoleGuard requiredRole={['admin', 'customer-service']}>
                          <InternalModuleLoader />
                        </RoleGuard>
                      </PrivateRoute>
                    } 
                  />

                  {/* Catch all route */}
                  <Route 
                    path="*" 
                    element={
                      <PrivateRoute>
                        <Navigate to="/" replace />
                      </PrivateRoute>
                    } 
                  />
                </Routes>
              </Suspense>
            </div>
          </DataProvider>
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
