import axiosInstance from '@/api/axiosInstance';
import { logger } from '@/utils/logger';
import { getCaseAttachments, CaseAttachment } from './getCaseAttachments';

interface SalesforceCaseDetail {
  Id: string;
  AccountId: string;
  CaseNumber: string;
  CreatedDate: string;
  RecordType: {
    Name: string;
  };
  Subject: string;
  Type: string;
  Sub_Type__c: string;
  Sub_Category__c: string;
  Description: string;
  Status: string;
  NetSuite_RMA__c?: string;
}

interface SalesforceComment {
  Id: string;
  ParentId: string;
  CommentBody: string;
  CreatedDate: string;
  CreatedById: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

interface SalesforceResponse {
  case: SalesforceCaseDetail;
  comments: SalesforceComment[];
  attachments: CaseAttachment[];
}

export interface CaseDetail {
  id: string;
  accountId: string;
  reference: string;
  createdDate: Date;
  requestType: string;
  subject: string;
  type: string;
  subType: string;
  subCategory: string;
  description: string;
  status: 'OPEN' | 'IN PROGRESS' | 'CLOSED' | 'BLOCKED';
  netsuiteRma?: string;
  comments: {
    id: string;
    body: string;
    createdDate: Date;
    createdBy: {
      id: string;
      firstName: string;
      lastName: string;
      fullName: string;
    };
  }[];
  attachments: CaseAttachment[];
}

export const mapStatus = (sfStatus: string): 'OPEN' | 'IN PROGRESS' | 'CLOSED' | 'BLOCKED' => {
  const statusMap: { [key: string]: 'OPEN' | 'IN PROGRESS' | 'CLOSED' | 'BLOCKED' } = {
    'Open': 'OPEN',
    'Pending Review': 'IN PROGRESS',
    'Resolved': 'CLOSED',
    // Add other mappings as needed
  };
  return statusMap[sfStatus] || 'OPEN';
};

export const getCase = async (caseNumber: string): Promise<CaseDetail> => {
  try {
    logger.info('Fetching case details:', { metadata: { caseNumber } });
    const response = await axiosInstance.get<SalesforceResponse>(
      `/i/s/c/${caseNumber}`
    );
    
    // Validate response
    if (!response.data?.case) {
      logger.error('Case not found:', { metadata: { caseNumber } });
      throw new Error('Case not found');
    }

    const { case: record, comments, attachments } = response.data;
    
    logger.debug('Attachments from response:', { 
      metadata: { attachments } 
    });
    
    logger.debug('Download URLs:', {
      metadata: attachments?.map((attachment, index) => ({
        index: index + 1,
        title: attachment.title,
        url: attachment.downloadUrl
      }))
    });

    const caseDetail: CaseDetail = {
      id: record.Id,
      accountId: record.AccountId,
      reference: record.CaseNumber || 'Unknown',
      createdDate: new Date(record.CreatedDate || Date.now()),
      requestType: record.RecordType?.Name || 'Unknown',
      subject: record.Subject || 'No Subject',
      type: record.Type || 'Unknown',
      subType: record.Sub_Type__c || 'Unknown',
      subCategory: record.Sub_Category__c || 'Unknown',
      description: record.Description || 'No Description',
      status: mapStatus(record.Status || 'Open'),
      netsuiteRma: record.NetSuite_RMA__c,
      comments: comments.map(comment => ({
        id: comment.Id,
        body: comment.CommentBody,
        createdDate: new Date(comment.CreatedDate),
        createdBy: {
          id: comment.createdBy.id,
          firstName: comment.createdBy.firstName,
          lastName: comment.createdBy.lastName,
          fullName: `${comment.createdBy.firstName} ${comment.createdBy.lastName}`.trim()
        }
      })),
      attachments: attachments || []
    };

    return caseDetail;
    
  } catch (error) {
    logger.error('Error fetching case:', { metadata: error });
    throw new Error(
      error instanceof Error 
        ? error.message 
        : 'Failed to fetch case details from server'
    );
  }
};
