import React from 'react';
import { Link } from 'react-router-dom';
import styles from './SupportMessageBar.module.css';

const SupportMessageBar: React.FC = () => {
  return (
    <div className={styles.supportMessageBar}>
      <div className={styles.iconContainer}>
        <span className={styles.iconText}>i</span>
      </div>
      <p className={styles.messageText}>
        For help with a new question or issue, please contact{' '}
        <Link to="/support" className={styles.link}>
          LuLaRoe Support
        </Link>
        .
      </p>
    </div>
  );
};

export default SupportMessageBar;