import React from 'react';

interface ProfileAvatarProps {
  initials: string;
  onClick?: () => void;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({ initials, onClick }) => {
  return (
    <div 
      className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer"
      onClick={onClick}
    >
      <span className="text-gray-600 font-medium text-sm select-none">{initials}</span>
    </div>
  );
};

export default ProfileAvatar;