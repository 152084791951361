import React, { ReactNode } from 'react';
import styles from './Tooltip.module.css';

interface TooltipProps {
  children: ReactNode;
  content: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
  return (
    <div className={styles.tooltipContainer}>
      {children}
      <div className={styles.tooltipContent}>
        {content}
      </div>
    </div>
  );
}; 