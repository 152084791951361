import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styles from './Breadcrumbs.module.css';

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(segment => segment);

  const breadcrumbItems = [
    { label: 'LuLaRoe Home', path: '/support' },
  ];

  if (pathSegments.includes('fundraisers')) {
    if (pathSegments.length === 1) {
      breadcrumbItems.push({ label: 'Fundraisers', path: '/fundraisers' });
    } else if (pathSegments[1] === 'new') {
      breadcrumbItems.push({ label: 'Fundraisers', path: '/fundraisers' });
      breadcrumbItems.push({ label: 'New Fundraiser', path: '/fundraisers/new' });
    } else {
      breadcrumbItems.push({ label: 'Fundraisers', path: '/fundraisers' });
      breadcrumbItems.push({ label: pathSegments[1], path: location.pathname });
    }
  } else if (pathSegments.includes('cases')) {
    if (pathSegments.length === 1) {
      breadcrumbItems.push({ label: 'Cases', path: '/cases' });
    } else if (pathSegments.length === 2) {
      breadcrumbItems.push({ label: 'Cases', path: '/cases' });
      breadcrumbItems.push({ label: pathSegments[1], path: location.pathname });
    }
  } else if (location.pathname === '/support') {
    breadcrumbItems.push({ label: 'Support', path: '/support' });
  } else if (location.pathname === '/settings') {
    breadcrumbItems.push({ label: 'Settings', path: '/settings' });
  } else if (location.pathname === '/knowledge') {
    breadcrumbItems.push({ label: 'Knowledge Base', path: '/knowledge' });
  } else if (pathSegments.includes('knowledge') && pathSegments.length === 2) {
    breadcrumbItems.push({ label: 'Knowledge Base', path: '/knowledge' });
    breadcrumbItems.push({ label: 'Article', path: location.pathname });
  }

  const showBreadcrumbs = breadcrumbItems.length > 1;

  return (
    <div className={styles.container}>
      <nav className={styles.nav}>
        <ol className={`${styles.list} ${!showBreadcrumbs ? styles.hidden : ''}`}>
          {breadcrumbItems.map((item, index) => (
            <li key={index} className={styles.item}>
              {index > 0 && <span className={styles.separator}>/</span>}
              {index < breadcrumbItems.length - 1 ? (
                <Link to={item.path} className={styles.link}>
                  {item.label}
                </Link>
              ) : (
                <span className={styles.currentPage}>{item.label}</span>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumbs;