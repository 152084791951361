import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { Case, getCases } from '../api/platform/integrations/salesforce/case/getCases';
import { FundraiserListItem, getFundraisers } from '../api/platform/integrations/salesforce/fundraiser/getFundraisers';
import { Product, getActiveProducts } from '../api/platform/integrations/salesforce/product/getActiveProducts';
import { logger } from '@/utils/logger';

interface DataContextType {
  cases: Case[];
  fundraisers: FundraiserListItem[];
  products: Product[];
  refreshCases: () => Promise<void>;
  refreshFundraisers: () => Promise<void>;
  refreshProducts: () => Promise<void>;
  isInitialLoading: boolean;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [cases, setCases] = useState<Case[]>([]);
  const [fundraisers, setFundraisers] = useState<FundraiserListItem[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // Helper function to compare and update only changed items
  const updateWithChanges = <T extends { id?: string; fundraiserNumber?: string }>(
    currentItems: T[],
    newItems: T[]
  ): T[] => {
    const updatedItems = [...currentItems];
    
    newItems.forEach(newItem => {
      const identifier = newItem.id || newItem.fundraiserNumber;
      const index = updatedItems.findIndex(item => 
        (item.id && item.id === identifier) || 
        (item.fundraiserNumber && item.fundraiserNumber === identifier)
      );

      if (index === -1) {
        // New item, add it
        updatedItems.push(newItem);
      } else if (JSON.stringify(updatedItems[index]) !== JSON.stringify(newItem)) {
        // Item exists but has changes, update it
        updatedItems[index] = newItem;
      }
      // If item exists and hasn't changed, leave it as is
    });

    return updatedItems;
  };

  const refreshCases = async () => {
    try {
      logger.info('Refreshing cases...');
      const newCases = await getCases();
      setCases(currentCases => updateWithChanges(currentCases, newCases));
    } catch (error) {
      logger.error('Error refreshing cases:', { metadata: error });
    }
  };

  const refreshFundraisers = async () => {
    try {
      logger.info('Refreshing fundraisers...');
      const newFundraisers = await getFundraisers();
      setFundraisers(currentFundraisers => 
        updateWithChanges(currentFundraisers, newFundraisers)
      );
    } catch (error) {
      logger.error('Error refreshing fundraisers:', { metadata: error });
    }
  };

  const refreshProducts = async () => {
    try {
      logger.info('Refreshing products...');
      const newProducts = await getActiveProducts();
      setProducts(newProducts);
      logger.info('Products refreshed:', {
        metadata: {
          count: newProducts.length,
          families: Array.from(new Set(newProducts.map(p => p.family)))
        }
      });
    } catch (error) {
      logger.error('Error refreshing products:', { metadata: error });
    }
  };

  // Initial data load when authenticated
  useEffect(() => {
    const initializeData = async () => {
      if (isAuthenticated) {
        setIsInitialLoading(true);
        try {
          logger.info('Initializing data...');
          await Promise.all([
            refreshCases(),
            refreshFundraisers(),
            refreshProducts()
          ]);
          logger.info('Data initialization complete');
        } catch (error) {
          logger.error('Error initializing data:', { metadata: error });
        }
        setIsInitialLoading(false);
      }
    };

    initializeData();
  }, [isAuthenticated]);

  return (
    <DataContext.Provider value={{ 
      cases, 
      fundraisers,
      products,
      refreshCases, 
      refreshFundraisers,
      refreshProducts,
      isInitialLoading 
    }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
}; 