import axiosInstance from '@/api/axiosInstance';
import { AxiosError } from 'axios';
import { logger } from '@/utils/logger';

interface VerifyAuthResponse {
  authenticated: boolean;
  message: string;
  role: string;
  user: {
    firstName: string;
    lastName: string;
    retailerId: string;
  };
}

interface AuthError {
  error: string;
  code: 'missing_token' | 'invalid_token' | 'expired_token' | 'user_not_found';
}

export async function verifyAuth(): Promise<VerifyAuthResponse> {
  logger.info('Verifying authentication...');
  try {
    const response = await axiosInstance.get<VerifyAuthResponse>('/auth/verify', {
      withCredentials: true,
    });
    
    logger.info('Auth verification successful');
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const authError = error.response?.data as AuthError;
      logger.error('Auth verification failed:', {
        metadata: {
          status: error.response?.status,
          error: authError
        }
      });
      throw error;
    }
    logger.error('Auth verification failed with unexpected error:', {
      metadata: error
    });
    throw error;
  }
} 