import axiosInstance from '@/api/axiosInstance';
import { logger } from '@/utils/logger';

interface SalesforceResponse {
  totalSize: number;
  done: boolean;
  records: SalesforceCase[];
}

interface SalesforceCase {
  Id: string;
  CaseNumber: string;
  Subject: string;
  Status: string;
  CreatedDate: string;
  RecordType: {
    Name: string;
  };
}

export interface Case {
  id: string;
  reference: string;
  summary: string;
  requestType: string;
  status: 'OPEN' | 'IN PROGRESS' | 'CLOSED' | 'BLOCKED';
  date: Date;
}

export type SortField = keyof Case;
export type SortOrder = 'asc' | 'desc';

const mapStatus = (sfStatus: string): Case['status'] => {
  const statusMap: { [key: string]: Case['status'] } = {
    'Open': 'OPEN',
    'Pending Review': 'IN PROGRESS',
    'Resolved': 'CLOSED',
    // Add other mappings as needed
  };
  return statusMap[sfStatus] || 'OPEN';
};

const extractRequestType = (subject: string): string => {
  // Extract the type from "Home - 60 - {Type} - {Subtype}"
  const parts = subject?.split(' - ') || [];
  return parts[2] || 'Unknown';
};

export const getCases = async (): Promise<Case[]> => {
  logger.info('Fetching cases from API...');
  const startTime = performance.now();
  
  try {
    const response = await axiosInstance.get<SalesforceResponse>(
      '/i/s/c'
    );
    
    const endTime = performance.now();
    logger.debug(`Cases API call completed in ${Math.round(endTime - startTime)}ms`);
    
    // Validate response structure
    if (!response.data || !Array.isArray(response.data.records)) {
      logger.error('Invalid response structure:', {
        metadata: response.data
      });
      throw new Error('Invalid response from server');
    }
    
    const cases = response.data.records
      .map((record): Case => ({
        id: record.Id,
        reference: record.CaseNumber || 'Unknown',
        summary: record.Subject || 'No Subject',
        requestType: record.RecordType?.Name || extractRequestType(record.Subject || ''),
        status: mapStatus(record.Status || 'Open'),
        date: new Date(record.CreatedDate || Date.now())
      }))
      .sort((a, b) => b.date.getTime() - a.date.getTime());

    logger.info(`Processed ${cases.length} cases`);
    return cases;
  } catch (error) {
    logger.error('Error fetching cases:', { metadata: error });
    throw new Error(
      error instanceof Error 
        ? error.message 
        : 'Failed to fetch cases from server'
    );
  }
};
